

import { Link } from "react-router-dom"
import bg from "../images/bg_mobile.webp"
import Contact from "./Contact"
import Contact2 from "./Contact2"
import BG from "../projects/BrightWorld-MA.webp"

const BrightWorldMobileApp=()=>{
    return <>
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/">
                  Portfolio <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                BrightWorld Mobile App <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread" />
            <p className="breadcrumbs">
              <span className="mr-2">
                MCodez IT Solutions is delighted to showcase the "Bright World"
                app, a robust corporate communication tool designed for Bright
                World Inc. This mobile application fosters seamless communication
                within the company, enhancing collaboration and information
                sharing among employees.
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Website Application</span>
          <h2>BrightWorld Mobile App</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img
                src={BG}
                alt=""
                className="img-fluid"
              />
            </p>
            <span className="portfolio-category">Mobile Application</span>
            <h2 className="mb-3 heading-title">BrightWorld Mobile App</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Engaging Company Feed: </b> The Bright World app features an
                intuitive company feed where employees can share updates, post
                announcements, and engage in discussions. This keeps everyone
                informed and connected.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Real-time Notifications: </b>Employees receive real-time
                notifications for new posts, comments, and likes, ensuring they
                are always upto-date with the latest company news and events.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Interactive Posts:</b> Users can interact with posts by liking,
                commenting, and sharing their thoughts, fostering a collaborative
                and engaging work environment.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Multimedia Support: </b> The app supports various multimedia
                formats, allowing employees to share images, videos, and documents
                directly within the feed for richer communication.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>User-friendly Interface: </b>The app boasts a sleek and modern
                interface that is easy to navigate, ensuring a seamless user
                experience for all employees.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
                <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/services/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      <i>
                        "The Bright World app has revolutionized our internal
                        communications. It has made it easier for our employees to
                        stay connected and informed, boosting overall productivity
                        and engagement. MCodez IT Solutions delivered an
                        exceptional app that meets all our needs." –
                      </i>
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">-Bright World</p>
                        <span className="position">Inc. Management</span>
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-php" />
        <span className="flaticon-mysql" />
        <span className="flaticon-laravel" />
        <span className="flaticon-flutter" />
        <span className="flaticon-dart" />
        <span className="flaticon-firebase" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
      <Contact/>
    </section>
   
    
    
      {/*?php include("./components/contact.php") ?*/}
    
  </>
  
}
export default BrightWorldMobileApp