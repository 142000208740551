import { Link } from "react-router-dom";
import bg_mobile from "../images/bg_mobile.webp";
import staff_app from "../projects/Staff-MA.webp";

const MySchoolStaff = () => {
  return (
    <>
      {/* ?php include("./components/header.php"); ? */}
      <section
        className="hero-wrap hero-wrap-2"
        style={{ backgroundImage: `url(${bg_mobile})` }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end">
            <div className="col-md-9 pb-5">
              <p className="breadcrumbs">
                <span className="mr-2">
                  <Link to="/">
                    Portfolio <i className="fa fa-chevron-right"></i>
                  </Link>
                </span>{" "}
                <span>
                  MySchool Staff App <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 className="mb-0 bread">MySchool Staff App</h1>
              <p className="breadcrumbs">
                <span className="mr-2">
                  At MCodez IT Solutions, we excel in creating intuitive and
                  efficient solutions tailored to the specific needs of our
                  clients. Our latest project is a comprehensive School
                  Management App developed to assist school staff in managing
                  attendance, assigning homework, and recording test and exam
                  results.<i className="fa fa-chevron-right"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container py-4 mt-2">
        <div className="row justify-content-center mb-5">
          <div className="col-md-7 heading-section text-center">
            <span className="subheading">Mobile Application</span>
            <h2>MySchool Staff App</h2>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "-10%" }}></div>
      <section className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
              <p style={{ textAlign: "center" }}>
                <img
                  src={staff_app}
                  alt=""
                  className="img-fluid"
                />
              </p>
              <span className="portfolio-category">Mobile Application</span>
              <h2 className="mb-3 heading-title">MySchool Staff App</h2>
              <ul className="pricing-text mb-2 list-unstyled" style={{ color: "black" }}>
                <li>
                  <span className="fa fa-check-circle mr-2"></span>
                  <b>Secure Login:</b> Staff members can securely log in using
                  their email and password, ensuring that only authorized
                  personnel can access the system.
                </li>
                <li>
                  <span className="fa fa-check-circle mr-2"></span>
                  <b>Comprehensive Dashboard:</b> The dashboard provides a clear
                  and concise overview of total students and their attendance
                  status (present, absent, and on leave).
                </li>
                <li>
                  <span className="fa fa-check-circle mr-2"></span>
                  <b>Attendance Tracking:</b> Staff can easily mark attendance
                  with a single tap. Each student's attendance status is
                  displayed alongside their name and contact information,
                  facilitating quick communication if needed.
                </li>
                <li>
                  <span className="fa fa-check-circle mr-2"></span>
                  <b>Homework Management:</b> Teachers can assign and track
                  homework, ensuring students stay on top of their assignments.
                  The app allows for easy monitoring of homework submission
                  status.
                </li>
                <li>
                  <span className="fa fa-check-circle mr-2"></span>
                  <b>Test and Exam Results:</b> Teachers can record and manage
                  test and exam results, providing a centralized location for
                  student performance data. This feature helps in maintaining
                  accurate academic records.
                </li>
                <li>
                  <span className="fa fa-check-circle mr-2"></span>
                  <b>Real-Time Data:</b> All data, including attendance,
                  homework, and test results, is updated in real-time, allowing
                  for accurate and up-to-date records.
                </li>
              </ul>

              <div className="tag-widget post-tag-container mb-5 mt-5">
                <div className="tagcloud">
                <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                  <a href="#" className="tag-cloud-link">
                    Digital Marketing
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section testimony-section ftco-no-pt bg-light">
        <div className="overlay"></div>
        <div className="container">
          <div className="row pb-4">
            <div className="col-md-7 heading-section">
              <span className="subheading" style={{ marginTop: "10%" }}>
                Testimonial
              </span>
              <h2 className="mb-4">
                People Say <br />
                About This Work
              </h2>
            </div>
          </div>
        </div>
        <div className="container-fluid px-lg-5">
          <div className="row">
            <div className="col-md-12">
              <div className="card" style={{ borderRadius: "30px" }}>
                <div className="item">
                  <div className="testimony-wrap py-4">
                    <div className="text">
                      <p className="mb-4">
                        <i>
                          "MCodez IT Solutions has transformed our
                          administrative processes with their innovative app.
                          The user-friendly interface and real-time data updates
                          have made it incredibly easy for our staff to manage
                          attendance, homework, and academic records accurately.
                          Their team's professionalism and dedication to
                          understanding our needs were commendable. We are
                          thrilled with the results and highly recommend their
                          services."
                        </i>
                      </p>
                      <div className="d-flex align-items-center">
                        <div
                          className="user-img"
                          style={{ backgroundImage: "url(images/person_1.jpg)" }}
                        ></div>
                        <div className="pl-3">
                          <p className="star">
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star"></span>
                            <span className="fa fa-star-o"></span>
                          </p>
                          <p className="name">My School Administration</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-no-pb">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-7 heading-section">
              <span className="subheading">Tech Stack</span>
              <h2>Technologies Used Are</h2>
            </div>
          </div>
          <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
            <span className="flaticon-php"></span>
            <span className="flaticon-mysql"></span>
            <span className="flaticon-flutter"></span>
            <span className="flaticon-laravel"></span>
            <span className="flaticon-dart"></span>
            <span className="flaticon-firebase"></span>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-no-pb">
        <div className="container">
          <div className="row">
            <div className="overlay"></div>
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <div className="same-app">
                <h1>Do you want your mobile app developed?</h1>
                <a
                  className="btn btn-primary"
                  href="./contact-us.php"
                  style={{ padding: "17px" }}
                >
                  Click here to Contact Us
                </a>
                <span>or</span>
                <h2>Reach us at</h2>
                <div className="reach-us">
                  <a href="mailto:contact@mcodez.com">contact@mcodez.com</a>
                  <span>|</span>
                  <a href="tel:+91 79883 37165">+91 79883 37165</a>
                  <span>|</span>
                  <a
                    href="https://api.whatsapp.com/send?phone=917988337165&text=Can%20I%20get%20more%20info%20about%20your%20services?"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Whatsapp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MySchoolStaff;

