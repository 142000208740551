import Contact2 from "./Contact2";
import web2 from "../images/web2.webp";
import Contact from "./Contact";
import imageV from "../projects/VLA-SD.webp"
import { Link } from "react-router-dom";




const VLA=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${web2})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/">
                  Portfolio <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                VLA Web App <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">VLA Web App</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                MCodez IT Solutions is pleased to introduce the VLA, a specialized
                e-commerce platform dedicated to the sale of steel products. This
                platform is designed to streamline the buying process for
                industrial clients, providing a seamless and efficient online
                shopping experience for a wide range of steel products.
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Website Application</span>
          <h2>VLA Web App</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img src={imageV} alt="" className="img-fluid" />
            </p>
            <span className="portfolio-category">Website Application</span>
            <h2 className="mb-3 heading-title">VLA Web App</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Secure User Authentication: </b>Secure login system ensuring
                authorized access to the platform for both buyers and sellers
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Product Catalog: </b>Comprehensive catalog of steel products
                with detailed specifications, making it easy for customers to find
                exactly what they need.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Order Management: </b>Robust order management system allowing
                users to track their orders, manage deliveries, and handle returns
                efficiently.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Real-time Notifications:</b>Instant notifications for order
                confirmations, shipping updates, and promotions to keep customers
                informed.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Responsive Design:</b>Optimized for various devices, ensuring a
                consistent and user-friendly experience on desktops, tablets, and
                mobile phones.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
              <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      <i>
                        "The VLA platform developed by MCodez IT Solutions has
                        significantly streamlined our sales process, providing our
                        clients with an efficient and reliable way to purchase
                        steel products online. The platform meets all our
                        expectations and more."
                      </i>
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">– Administrator</p>
                        <span className="position">VLA Management</span>
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-javascript" />
        <span className="flaticon-php" />
        <span className="flaticon-laravel" />
        <span className="flaticon-jquery" />
        <span className="flaticon-mysql" />
        <span className="flaticon-bootstrap" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
      <Contact/>
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}

export default VLA;