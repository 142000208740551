
import { Link } from "react-router-dom";
import custmon from "../images/customSoftwareDev.webp";
import Legal from "../projects/Legalstix-CRM-SD.webp"
import Lev from "../projects/LevNext-CRM-SD.webp"
import DrAshok from "../projects/Dr.Ashok-Ecom.webp"
import MyCart from "../projects/MyCart-Ecomm.webp"
import ycc from "../projects/YCC-SD.webp"
import propoint from  "../projects/ProPoint-Web.webp"
import Vla from "../projects/VLA-SD.webp"
import propointSD from "../projects/Propoint-SD.webp"

const WebDevelopment=()=>{
  return <>
  {/*?php include("components/header.php") ?*/}
  <section
    className="hero-wrap hero-wrap-2"
    style={{ backgroundImage: `url(${custmon})` }}
  >
    <div className="overlay" />
    <div className="container">
      <div className="row no-gutters slider-text align-items-end">
        <div className="col-md-9  pb-5">
          <p className="breadcrumbs">
            <span className="mr-2">
              <a href="portfolio.php">
                Portfolio <i className="fa fa-chevron-right" />
              </a>
            </span>{" "}
            <span>
              Web Development <i className="fa fa-chevron-right" />
            </span>
          </p>
          <h1 className="mb-0 bread">Web Development</h1>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
    <div className="container">
      <div className="row d-flex">
        <div className="col-md-12 about-intro">
          <div className="row d-flex">
            <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4 heading">Leads Management System</h2>
                  <p>
                    MCodez IT Solutions proudly presents the Levnext Projects
                    CRM, a comprehensive customer relationship management
                    platform designed to enhance business operations and client
                    interactions. This platform offers robust tools for managing
                    client information, tracking sales, and optimizing customer
                    engagement strategies.
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
            <div className="col-md-6 order-1 order-md-2 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${Legal})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>Leads Management System</span>
                  <h3>
                    <Link to ="/portfolio/leads-management-system">Interior Design</Link>
                  </h3>
                  <Link 
                    to="/portfolio/leads-management-system"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            {/* Section 2: Content > Image on both Web and Mobile */}
            <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${Lev})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>Project Tracker</span>
                  <h3>
                    <Link to="../portfolio/project-tracker">Interior Design</Link>
                  </h3>
                  <Link 
                    to="../portfolio/project-tracker"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-2 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">Project Traker</h2>
                  <p>
                    MCodez IT Solutions proudly presents the Levnext Projects
                    CRM, a comprehensive customer relationship management
                    platform designed to enhance business operations and client
                    interactions. This platform offers robust tools for managing
                    client information, tracking sales, and optimizing customer
                    engagement strategies.
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
            {/* Section 1: Image > Content on Web, Content > Image on Mobile */}
            <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">Dr. Ashok Ecom</h2>
                  <p>
                    At MCodez IT Solutions, we specialize in creating robust
                    e-commerce platforms that cater to unique client needs. One
                    of our premier projects is the development of an online
                    store for Dr. Ashok Virility, a center for sexual natural
                    medicine and treatment.
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
            <div className="col-md-6 order-1 order-md-2 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${DrAshok})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>Dr. Ashok Ecom</span>
                  <h3>
                    <Link to="/portfolio/dr-ashok-ecommerce">Interior Design</Link>
                  </h3>
                  <Link
                    to="/portfolio/dr-ashok-ecommerce"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            {/* Section 2: Content > Image on both Web and Mobile */}
            <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${MyCart})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>MyCart Pro</span>
                  <h3>
                    <Link to="../portfolio/project-tracker">Interior Design</Link>
                  </h3>
                  <Link
                    to="../portfolio/project-tracker"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-2 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">MyCart Pro</h2>
                  <p>
                    MCodez IT Solutions is proud to present My Cart Pro, a
                    comprehensive e-commerce platform designed to facilitate
                    seamless online shopping experiences. This platform caters
                    to both buyers and sellers, offering a wide range of
                    products and robust features to enhance user engagement
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
            {/* Section 1: Image > Content on Web, Content > Image on Mobile */}
            <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">YOGYATA WEB APPLICATION</h2>
                  <p>
                    At MCodez IT Solutions, we specialize in creating secure and
                    efficient web applications tailored to specific business
                    needs. One of our standout projects is the YCC Management
                    Portal, designed to streamline the operations and management
                    of Youth Cultural Center activities.
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
            <div className="col-md-6 order-1 order-md-2 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${ycc})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>YOGYATA WEB APPLICATION</span>
                  <h3>
                    <Link to="/ycc-mobile-app">Interior Design</Link>
                  </h3>
                  <Link
                    to="/ycc-mobile-app"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            {/* Section 2: Content > Image on both Web and Mobile */}
            <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${propoint})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>ProPoint Web Application</span>
                  <h3>
                    <Link to="../portfolio/propoint-website">Interior Design</Link>
                  </h3>
                  <Link
                    to="../portfolio/propoint-website"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-2 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">ProPoint Web Application</h2>
                  <p>
                    At MCodez IT Solutions, we collaborate with industry leaders
                    to create innovative digital solutions that streamline and
                    enhance business processes. One of our premier projects is
                    ProPoint, a comprehensive recruitment platform designed to
                    connect businesses with top-tier talent efficiently and
                    effectively.
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
            {/* Section 3: Content > Image on both Web and Mobile */}
            <div className="col-md-6 order-2 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">VLA Web Application</h2>
                  <p>
                    MCodez IT Solutions is pleased to introduce the VLA, a
                    specialized e-commerce platform dedicated to the sale of
                    steel products. This platform is designed to streamline the
                    buying process for industrial clients, providing a seamless
                    and efficient online shopping experience for a wide range of
                    steel products.
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
            <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${Vla})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>VLA Web Application</span>
                  <h3>
                    <Link to="../portfolio/vla">Interior Design</Link>
                  </h3>
                  <Link
                    to="../portfolio/vla"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
              <div
                className="project-wrap img d-flex align-items-end"
                style={{
                  backgroundImage: `url(${propointSD})`,
                  borderRadius: 30
                }}
              >
                <div className="text">
                  <span>ProPoint Connect</span>
                  <h3>
                    <Link to="../portfolio/propoint-web-app">Interior Design</Link>
                  </h3>
                  <Link
                    to="../portfolio/propoint-web-app"
                    className="icon d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-2 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">ProPoint Connect</h2>
                  <p>
                    MCodez IT Solutions proudly presents ProPoint Connect, an
                    advanced job portal designed to streamline the hiring
                    process for administrators, clients, and vendors. This
                    platform provides a centralized solution for job postings,
                    candidate management, and vendor coordination, ensuring an
                    efficient and effective recruitment process.
                  </p>
                </div>
              </div>
              <div style={{ border: "2px solid black" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*?php include("components/footer.php") ?*/}
</>

}

export default WebDevelopment