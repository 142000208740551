import { Link } from "react-router-dom";
import web2 from "../images/web2.webp";
import MySchoolERP from "../projects/MySchoolERP-SD.webp";


const MySchoolErp=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url("${web2}")` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/">
                  Portfolio <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                MySchool ERP <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">MySchool ERP</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                MCodez IT Solutions presents "My School," a comprehensive school
                management platform designed to streamline administrative tasks
                and enhance communication between teachers, students, and parents.
                This platform provides a centralized hub for managing various
                school activities efficiently
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Website Application</span>
          <h2>MySchool ERP</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img
                src={MySchoolERP}
                alt=""
                className="img-fluid"
              />
            </p>
            <span className="portfolio-category">Website Application</span>
            <h2 className="mb-3 heading-title">MySchool ERP</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Parent Portal: </b>Secure login for parents to access their
                child's academic progress, attendance, and upcoming events.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Live Classes: </b>Integration of live class schedules with easy
                access for students to join sessions, fostering an interactive
                learning environment.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Announcements and Notifications:</b>Real-time updates on school
                announcements, exam schedules, and important notifications to keep
                parents and students informed.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Event Calendar:</b> A detailed calendar highlighting upcoming
                school events, holidays, and important dates, helping parents and
                students stay organized.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>User-Friendly Interface: </b> Intuitive and responsive design
                ensuring ease of use across various devices including desktops,
                tablets, and smartphones.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
              <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      <i>
                        "The My School platform developed by MCodez IT Solutions
                        has revolutionized our school's administrative processes.
                        It has made it easier for parents to stay informed about
                        their child's progress and for teachers to manage their
                        schedules effectively."
                      </i>
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">– My School Administration</p>
                        <span className="position" />
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-php" />
        <span className="flaticon-mysql" />
        <span className="flaticon-laravel" />
        <span className="flaticon-bootstrap" />
        <span className="flaticon-jquery" />
        <span className="flaticon-javascript" />
        <span className="flaticon-node" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}

export default MySchoolErp;