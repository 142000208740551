import React, { useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import backg from "../images/bg.webp";
import bg_mobile from "../images/bg_mobile.webp"
import web2 from "../images/web2.webp";
import software from "../images/software.webp"
import smm2 from "../images/smm2.webp";
import mcodez from "../images/mcodez_bg.webp";
import MySchoolMA from "../projects/MySchool-MA.webp";
import AdminMA from "../projects/Admin-MA.webp";
import StaffMA from "../projects/Staff-MA.webp";
import DrAshok from "../projects/Dr.Ashok-Ecom.webp";
import YCCSD from "../projects/YCC-SD.webp";
import LegalstixCRM from "../projects/Legalstix-CRM-SD.webp";
import Service from './Service';
import about from "../images/about.webp";
import Faq from './Faq';
import { Link } from 'react-router-dom';
import './Header.css';
import bgMobile from "../images/bg_mobile_2.webp"
import custom from "../images/customSoftwareDev.webp"
import ai from "../images/ai.webp"
// import person1 from "../images/person_1.jpg";
// import person2 from "../images/person_2.jpg";
// import person3 from "../images/person_3.jpg";
// import image1 from "../images/image_1.jpg";
// import image2 from "../images/image_2.jpg";
// import image3 from "../images/image_3.jpg";




const Home=()=>{

    return <>
    {/*?php 
  include("./components/header.php");
  ?*/}
    {/* BACKGROUND IMAGES START */}
    <section className="hero-wrap">
    <OwlCarousel
        className="home-slider owl-carousel js-fullheight"
        items={1}
        loop
        autoplay
        nav
        dots
        animateOut="fadeOut" // Optional animations
      >
      
        <div
          className="slider-item js-fullheight"
          style={{ backgroundImage: `url(${backg})` }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row d-flex no-gutters slider-text js-fullheight align-items-center align-items-stretch">
              <div className="col-md-7  d-flex align-items-center">
                <div className="text w-100 mt-5">
                  <span className="subheading">Welcome to</span>
                  <h1>MCodez IT Solutions</h1>
                  <p className="mb-4">
                    Welcome to MCodez IT Solutions, your gateway to cutting-edge
                    digital solutions! As one of the top IT companies in India, we
                    specialize in delivering innovative mobile app development
                    services, website development services, and software
                    development services tailored to your unique business needs.
                  </p>
                  <p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Get Started
                    </Link>{" "}
                    <Link to="/portfolio" className="btn btn-white">
                      View Portfolio
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="slider-item js-fullheight"
          style={{ backgroundImage: `url(${bg_mobile})` }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row d-flex no-gutters slider-text js-fullheight align-items-center align-items-stretch">
              <div className="col-md-7  d-flex align-items-center">
                <div className="text w-100 mt-5">
                  <h1>Mobile App Development Services</h1>
                  <p className="mb-4">
                    Modez IT Solutions offers the best Mobile App Development
                    Services from concept to launch, we develop custom mobile apps
                    for iOS app development and Android app development platforms,
                    focusing on user experience, functionality, and scalability.
                  </p>
                  <p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Get Started
                    </Link>{" "}
                    <Link to="/portfolio" className="btn btn-white">
                      View Portfolio
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="slider-item js-fullheight"
          style={{ backgroundImage: `url(${web2})` }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row d-flex no-gutters slider-text js-fullheight align-items-center align-items-stretch">
              <div className="col-md-7  d-flex align-items-center">
                <div className="text w-100 mt-5">
                  <h1>Website Development Services</h1>
                  <p className="mb-4">
                    MCodez IT Solutions offers top Website Development Services in
                    India we create visually stunning and user-friendly websites
                    that not only captivate visitors but also drive conversions
                    and enhance your online presence.
                  </p>
                  <p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Get Started
                    </Link>{" "}
                    <Link to="/portfolio" className="btn btn-white">
                      View Portfolio
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="slider-item js-fullheight"
          style={{ backgroundImage: `url(${software})` }}
        > 
          <div className="overlay" />
          <div className="container">
            <div className="row d-flex no-gutters slider-text js-fullheight align-items-center align-items-stretch">
              <div className="col-md-7  d-flex align-items-center">
                <div className="text w-100 mt-5">
                  <h1>Custom Software Development Services</h1>
                  <p className="mb-4">
                    MCodez IT Solutions offers the best custom or web Software
                    Development Services: Our team specializes in developing
                    robust and scalable software solutions that streamline
                    processes, enhance productivity, and drive business growth.
                  </p>
                  <p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Get Started
                    </Link>{" "}
                    <Link to="/portfolio" className="btn btn-white">
                      View Portfolio
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="slider-item js-fullheight"
          style={{ backgroundImage: `url(${smm2})` }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row d-flex no-gutters slider-text js-fullheight align-items-center align-items-stretch">
              <div className="col-md-7  d-flex align-items-center">
                <div className="text w-100 mt-5">
                  <h1>Social Media Management Services</h1>
                  <p className="mb-4">
                    MCodez IT Solutions offers the top Social Media Management
                    Services in India. We help your brand shine across all social
                    platforms, driving engagement, loyalty, and growth. Partner
                    with MCodez IT Solution and l
                  </p>
                  <p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Get Started
                    </Link>{" "}
                    <Link to="/portfolio" className="btn btn-white">
                      View Portfolio
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="slider-item js-fullheight"
          style={{ backgroundImage: `url(${mcodez})` }}
        >
          <div className="overlay" />
          <div className="container">
            <div className="row d-flex no-gutters slider-text js-fullheight align-items-center align-items-stretch">
              <div className="col-md-7  d-flex align-items-center">
                <div className="text w-100 mt-5">
                  <h1>Best IT Company in India</h1>
                  <p className="mb-4">
                    Partner with MCodez IT Solutions today and experience the
                    difference that top-tier IT services can make for your
                    business. Let's innovate, grow, and succeed together and
                    Welcome to a world of possibilities with MCodez IT Solutions –
                    where innovation meets excellence!
                  </p>
                  <p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Get Started
                    </Link>{" "}
                    <Link to="/portfolio" className="btn btn-white">
                      View Portfolio
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </OwlCarousel>
    </section>
   
    {/* BACKGROUND IMAGES END */}
    {/* SERVICES SECTION START */}
   
    <Service/>
    <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
  <div className="container">
    <div className="row d-flex">
      <div className="col-md-12 about-intro">
        <div className="row d-flex">
          <div className="col-md-6 d-flex align-items-stretch">
            <div
              className="img d-flex align-items-center align-self-stretch justify-content-center"
              style={{ backgroundImage: `url(${about})` }}
            ></div>
          </div>
          <div className="col-md-6 pl-md-5 py-5">
            <div className="row justify-content-start pb-3 pt-md-5">
              <div className="col-md-12 heading-section ftco-animate">
                <span className="subheading">
                  Welcome to MCodez IT Solutions
                </span>
                <h2 className="mb-4">
                  Highest <br />
                  Creative Standards
                </h2>
                <p>
                  At MCodez IT Solutions, we are dedicated to empowering
                  businesses with cutting-edge digital solutions. As one of the
                  top IT companies in India, we specialize in providing a wide
                  range of services, including UI/ UX design, mobile app
                  development, website development, and software development,
                  and digital marketing.
                </p>
                <div className="year-stablish d-flex">
                  <div className="icon2 d-flex align-items-center justify-content-center">
                    <span className="flaticon-light-bulb" />
                  </div>
                  <div className="text pl-4">
                    <strong className="number" data-number={42}>
                      21
                    </strong>
                    <span>
                      Year Of
                      <br /> Experienced
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    {/* SERVICES SECTION START */}
    {/* ABOUT SECTION START */}

    {/* ABOUT SECTION END */}
    {/* COMPLETED SERVICE SECTION START */}
    <section
      className="ftco-section ftco-counter img"
      id="section-counter"
      style={{ backgroundImage: `url(${mcodez})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-3 d-flex counter-wrap ">
            <div className="block-18 d-flex align-items-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="flaticon-file" />
              </div>
              <div className="text pl-3">
                <strong className="number" data-number={4800}>
                  100
                </strong>
                <span>Project Completed</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex counter-wrap ">
            <div className="block-18 d-flex align-items-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="flaticon-waiter" />
              </div>
              <div className="text pl-3">
                <strong className="number" data-number={1000}>
                  30
                </strong>
                <span>Our Staff</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex counter-wrap ">
            <div className="block-18 d-flex align-items-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="flaticon-web-programming" />
              </div>
              <div className="text pl-3">
                <strong className="number" data-number={350}>
                  50
                </strong>
                <span>Services Provide</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex counter-wrap ">
            <div className="block-18 d-flex align-items-center">
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="flaticon-good-review" />
              </div>
              <div className="text pl-3">
                <strong className="number" data-number={7650}>
                  1000
                </strong>
                <span>Happy Customers</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* COMPLETED SERVICE SECTION END */}
    <section
      className="ftco-section ftco-about ftco-no-pt ftco-no-pb img mt-2"
      style={{ marginTop: 20 }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="row justify-content-start pb-3 pt-md-5">
              <div className="col-md-12 heading-section ">
                <h2 className="mb-4 heading">Our Services</h2>
                <p>
                  At MCodez IT Solutions, we understand the importance of staying
                  ahead in today's digital landscape. That's why we offer a
                  comprehensive suite of services designed to empower your
                  business for success. Whether you're looking to launch a
                  user-friendly mobile app, create a captivating website, or
                  develop custom software solutions, we have the expertise and
                  experience to bring your ideas to life.
                </p>
              </div>
            </div>
            <div className="road-map-main">
              <div className="road-map-wrapper">
                <div className="road-map-circle">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    UI/UX
                  </span>
                </div>
                <div className="road-map-card">
                  <h4 className="card-head">UI/UX Designing</h4>
                  <p className="card-text">
                    We create engaging and intuitive digital experiences with our
                    UI/UX design services. From user research and wireframing to
                    prototyping and usability testing, we ensure that your digital
                    products deliver exceptional user experiences that drive
                    engagement and satisfaction.
                  </p>
                </div>
              </div>
              <div className="road-map-wrapper">
                <div className="road-map-circle">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    Web Dev
                  </span>
                </div>
                <div className="road-map-card">
                  <h4 className="card-head">Website Development</h4>
                  <p className="card-text">
                    As a leading web development company in India, we excel in
                    delivering website development services that make your online
                    presence stand out. Our team of experienced developers and
                    designers craft visually appealing, user-friendly, and
                    responsive websites that drive traffic, conversions, and
                    business growth.
                  </p>
                </div>
              </div>
              <div className="road-map-wrapper">
                <div className="road-map-circle">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    Softwares
                  </span>
                </div>
                <div className="road-map-card">
                  <h4 className="card-head">Custom Software Development</h4>
                  <p className="card-text">
                    At MCodez IT Solutions, we offer end-to-end custom software
                    development services to streamline your business processes and
                    enhance productivity. Our software development agency in India
                    specializes in developing scalable, secure, and customized
                    software solutions that meet your specific requirements and
                    business objectives.
                  </p>
                </div>
              </div>
              <div className="road-map-wrapper">
                <div className="road-map-circle">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    Mobile Dev
                  </span>
                </div>
                <div className="road-map-card">
                  <h4 className="card-head">Mobile App Development Services</h4>
                  <p className="card-text">
                    Our mobile app development services cover a wide spectrum,
                    including Android app development, iOS app development, and
                    cross-platform app development. We leverage the latest
                    technologies and industry best practices to create intuitive,
                    engaging, and high-performing mobile applications that
                    resonate with your target audience.
                  </p>
                </div>
              </div>
              <div className="road-map-wrapper">
                <div className="road-map-circle">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    SMO
                  </span>
                </div>
                <div className="road-map-card">
                  <h4 className="card-head">Social Media Optimization</h4>
                  <p className="card-text">
                    At MCodez it Solutions, we boost your online presence and
                    drive results with our comprehensive Social Media Optimization
                    solutions. From Posting and advertising to social media
                    marketing and content creation, we have the expertise to help
                    you achieve your business goals in the digital realm.
                  </p>
                </div>
              </div>
              <div className="road-map-wrapper">
                <div className="road-map-circle">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    SEO
                  </span>
                </div>
                <div className="road-map-card">
                  <h4 className="card-head">Search Engine Optimization</h4>
                  <p className="card-text">
                    At MCodez IT Solutions, we elevate your online presence and
                    deliver results with our comprehensive Social Media
                    Optimization services. From strategic posting and targeted
                    advertising to impactful social media marketing and creative
                    content creation, our expertise ensures you achieve your
                    business goals in the digital realm. Let us help you harness
                    the power of social media to drive engagement, increase brand
                    awareness, and boost your bottom line.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Faq/>
    {/* TEAM SECTION START HERE  */}
    <section className="ftco-section">
      <div className="container">
        <div className="row no-gutters justify-content-between">
          <div className="col-md-7 col-lg-6 heading-section ">
            <span className="subheading">Why Choose Us?</span>
            <h2 className="mb-4">
              Expertise <br />
              Team Comprises
            </h2>
          </div>
        </div>
      </div>
      <div className="container container-2">
        <div className="row no-gutters justify-content-center">
          <div className="col-md-4 col-lg  d-flex">
            <ul className="icon-list">
              <li>
                <i className="fas fa-mobile-alt" />
                Expertise team comprises skilled professionals with expertise in{" "}
                <Link to="/mobile-app-development">mobile app development</Link>,{" "}
                <Link to="/services/web-development">web development</Link>, and{" "}
                <Link to="">software development</Link>.
              </li>
              <li>
                <i className="fas fa-check-circle" />
                Quality adhere to industry standards and best practices to deliver
                high-quality solutions that exceed expectations.
              </li>
              <li>
                <i className="fas fa-lightbulb" />
                Innovation with the latest technologies and trends to provide
                innovative solutions that drive results
              </li>
              <li>
                <i className="fas fa-user-friends" />
                Client-centric approach and work closely with you to understand
                your needs and deliver customized solutions
              </li>
              <li>
                <i className="fas fa-dollar-sign" />
                Affordable Pricing and pricing plans that fit your budget without
                compromising on quality
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    {/* TEAM SECTION END HERE  */}
    {/* PORTFOLIO START HERE  */}
    <section className="ftco-section ftco-portfolio bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row justify-content-between pb-5">
          <div className="col-md-6 col-lg-6 heading-section heading-section-white ">
            <span className="subheading">Recent Portfolio</span>
            <h2 className="mb-4">
              We Have Done <br />
              Many Latest Projects
            </h2>
          </div>
          <div className="col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
            <Link to="/portfolio" className="btn-custom">
              View All Projects <span className="fa fa-chevron-right" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${MySchoolMA})` }}
            >
              <div className="text">
                <span>My School Mobile Application</span>
                <h3>
                  <Link to="/portfolio/myschool-mobile-app">Interior Design</Link>
                </h3>
                <Link
                  to="/portfolio/myschool-mobile-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${AdminMA})` }}
            >
              <div className="text">
                <span>My School Admin App</span>
                <h3>
                  <Link to="/portfolio/myschool-admin-app">Interior Design</Link>
                </h3>
                <Link
                  to="/portfolio/myschool-admin-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${StaffMA})` }}
            >
              <div className="text">
                <span>My School Staff App</span>
                <h3>
                  <Link to="/portfolio/myschool-staff-app">Interior Design</Link>
                </h3>
                <Link
                  to="/portfolio/myschool-staff-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${DrAshok})` }}
            >
              <div className="text">
                <span>Dr. Ashok Ecom Portal</span>
                <h3>
                  <Link to="/portfolio/dr-ashok-ecommerce">Interior Design</Link>
                </h3>
                <Link
                  to="/portfolio/dr-ashok-ecommerce"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${YCCSD})` }}
            >
              <div className="text">
                <span>YCC Web Application</span>
                <h3>
                  <Link to="/portfolio/ycc-web-app">Interior Design</Link>
                </h3>
                <Link
                  to="/portfolio/ycc-web-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${LegalstixCRM})` }}
            >
              <div className="text">
                <span>Leads Mangement System</span>
                <h3>
                  <Link to="/portfolio/leads-management-system">Interior Design</Link>
                </h3>
                <Link
                  to="/portfolio/leads-management-system"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* PORTFOLIO SECTION END HERE  */}
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Testimonial</span>
            <h2 className="mb-4">
              People Say <br />
              About Our Works
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="carousel-testimony owl-carousel">
              <div className="item">
                <div className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        // style={{ backgroundImage: `url(${person1})` }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </p>
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        // style={{ backgroundImage: `url(${person2})` }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </p>
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        // style={{ backgroundImage: `url(${person3})` }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </p>
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        // style={{ backgroundImage: `url(${person1})` }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </p>
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        // style={{ backgroundImage: `url(${person2})` }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                        </p>
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center pb-4">
          <div className="col-md-12 heading-section text-center ">
            <span className="subheading">Our Blog</span>
            <h2 className="mb-4">Recent Post</h2>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col-md-4 ">
            <div className="blog-entry">
              <a
                href="blog-single.html"
                className="block-20"
                 style={{ backgroundImage: `url(${ai})` }}
              ></a>
              <div className="text d-block text-center">
                <div className="meta">
                  <p>
                    <Link to="#">
                      <span className="fa fa-calendar mr-2" />
                      Sept. 23, 2020
                    </Link>
                    <Link to="#">
                      <span className="fa fa-user mr-2" />
                      Admin
                    </Link>
                    <Link to="#" className="meta-chat">
                      <span className="fa fa-comment mr-2" /> 3
                    </Link>
                  </p>
                </div>
                <h3 className="heading">
                  <Link to="#">Marketing Strategies for Digital Ecosystem</Link>
                </h3>
                <p>
                  Far far away, behind the word mountains, far from the countries
                  Vokalia and Consonantia...
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="blog-entry">
               <a
                href="blog-single.html"
                className="block-20"
                 style={{ backgroundImage: `url(${custom})` }}
              ></a>
              <div className="text d-block text-center">
                <div className="meta">
                  <p>
                    <Link to="#">
                      <span className="fa fa-calendar mr-2" />
                      Sept. 23, 2020
                    </Link>
                    <Link to="#">
                      <span className="fa fa-user mr-2" />
                      Admin
                    </Link>
                    <Link to="#" className="meta-chat">
                      <span className="fa fa-comment mr-2" /> 3
                    </Link>
                  </p>
                </div>
                <h3 className="heading">
                  <Link to="#">Marketing Strategies for Digital Ecosystem</Link>
                </h3>
                <p>
                  Far far away, behind the word mountains, far from the countries
                  Vokalia and Consonantia...
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="blog-entry">
               <a
                href="blog-single.html"
                className="block-20"
                 style={{ backgroundImage: `url(${bgMobile})` }}
              ></a>
              <div className="text d-block text-center">
                <div className="meta">
                  <p>
                    <Link to="#">
                      <span className="fa fa-calendar mr-2" />
                      Sept. 23, 2020
                    </Link>
                    <Link to="#">
                      <span className="fa fa-user mr-2" />
                      Admin
                    </Link>
                    <Link to="#" className="meta-chat">
                      <span className="fa fa-comment mr-2" /> 3
                    </Link>
                  </p>
                </div>
                <h3 className="heading">
                  <Link to="#">Marketing Strategies for Digital Ecosystem</Link>
                </h3>
                <p>
                  Far far away, behind the word mountains, far from the countries
                  Vokalia and Consonantia...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*?php
  include("./components/footer.php");
  ?*/}
  </>
  

    }
    
    
    export default Home