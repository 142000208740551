// src/components/Loader.js
import React from 'react';
import './Loader.css'; // Add your loader styles here

const Loader = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

export default Loader;
