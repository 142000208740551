import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.css";


const Header=()=>{
    const location = useLocation();
    return <>
    <div className="wrap">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md d-flex align-items-center">
            <p className="mb-0 phone">
              <span className="mailus">Phone no:</span>{" "}
              <a href="tel:+91 7988337165">+91 7988337165</a> or{" "}
              <span className="mailus">email us:</span>{" "}
              <a href="mailto:contact@mcodez.com">
                <span
                  className="__cf_email__"
                  data-cfemail="53363e323a3f20323e233f3613363e323a3f7d303c3e"
                >
                  contact@mcodez.com
                </span>
              </a>
            </p>
          </div>
          <div className="col-12 col-md d-flex justify-content-md-end">
            <div className="social-media">
              <p className="mb-0 d-flex">
                <a
                  href="https://www.facebook.com/mcodezitsolutionskarnal/"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa-brands fa-facebook-f">
                    <i className="sr-only">Facebook</i>
                  </span>
                </a>
                <a
                  href="https://www.linkedin.com/company/MCodez-it-solutions/"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa-brands fa-x">
                    <i className="sr-only">X</i>
                  </span>
                </a>
                <a
                  href="https://www.instagram.com/MCodez_it_solutions/"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa-brands fa-instagram">
                    <i className="sr-only">Instagram</i>
                  </span>
                </a>
                <a
                  href="https://www.linkedin.com/company/MCodez-it-solutions/"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa-brands fa-linkedin">
                    <i className="sr-only">LinkedIn</i>
                  </span>
                </a>
                <a
                  href="https://www.youtube.com/@MCodez_IT_Solutions/featured"
                  target="_blank"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa-brands fa-youtube">
                    <i className="sr-only">YouTube</i>
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav
      className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
      id="ftco-navbar"
    >
      <div className="container">
        <Link className="navbar-brand" to="/">
          MCodez IT Solutions
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu" /> Menu
        </button>
        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            <li className={location.pathname == "/" ? "nav-item active" : "nav-item"}>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className={location.pathname == "/about" ? "nav-item active" : "nav-item"}>
              <Link to="/about" className="nav-link">
                About
              </Link>
            </li>
            <li className={location.pathname == "/services" ? "nav-item active" : "nav-item"}>
              <Link to="/services" className="nav-link">
                Services
              </Link>
            </li>
            <li className={location.pathname == "/portfolio" ? "nav-item active" : "nav-item"}>
              <Link to="/portfolio" className="nav-link">
                Portfolio
              </Link>
            </li>
            <li className={location.pathname == "/blogs" ? "nav-item active" : "nav-item"}>
              <Link to="/blogs" className="nav-link">
                Blog
              </Link>
            </li>
            <li className={location.pathname == "/contact-us" ? "nav-item active" : "nav-item"}>
              <Link to="/contact-us" className="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </>
  
}

export default Header;
