
import { Link } from "react-router-dom";
import uiux from "../images/ui-ux.webp";
import CS from "../images/custom-software-1.webp"
import YCC from "../projects/YCC-SD.webp";
import YCC_Mobileapp from "../projects/YCC_Mobileapp.webp";
import Legalstix from "../projects/Legalstix-CRM-SD.webp";


const UiUxDesigningDev=()=>{
  return <>
  {/*?php
  include("./components/header.php");
?*/}
  <section
    className="hero-wrap hero-wrap-2"
    style={{ backgroundImage: `url(${uiux})` }}
  >
    <div className="overlay" />
    <div className="container">
      <div className="row no-gutters slider-text align-items-end">
        <div className="col-md-9  pb-5">
          <p className="breadcrumbs">
            <span className="mr-2">
              <Link to="/">
                Home <i className="fa fa-chevron-right" />
              </Link>
              &nbsp;
              <Link to="/services">
                Services <i className="fa fa-chevron-right" />
              </Link>
            </span>{" "}
            <span>UI/UX Designing </span>
          </p>
          <h1 className="mb-0 bread">UI/UX Designing</h1>
          {/* <p class="breadcrumbs"><span class="mr-2">Our mobile app development services cover a wide spectrum, including Android app development, iOS app development, and cross-platform app development. We leverage the latest technologies and industry best practices to create intuitive, engaging, and high-performing mobile applications that resonate with your target audience.</span></p> */}
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-7 heading-section ">
          <span className="subheading">UI/UX Designing</span>
          <h2>EMPOWERING INNOVATION</h2>
          <p>
            User experience (UX) and user interface (UI) design play a crucial
            role in creating engaging and intuitive digital experiences. At
            MCodez IT Solutions, we specialize in UI/UX design services that
            prioritize usability, functionality, and aesthetics to deliver
            exceptional user experiences.
          </p>
        </div>
        <div className="col-md-5 ">
          <img
            src={CS}
            width="100%"
            height="auto"
            alt="Animated GIF"
          />
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-12 heading-section ">
          <span className="subheading">UI/UX Designing</span>
          <h2>Our UI/UX design services include</h2>
          <ul
            className="pricing-text mb-2 list-unstyled"
            style={{ color: "black" }}
          >
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>User Research and Analysis: </b> Understand your target
              audience, their needs, preferences, and pain points through
              thorough user research and analysis.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Wireframing and Prototyping: </b>We collaborate closely with
              our clients to understand their unique requirements, workflows,
              and pain points. This collaborative approach ensures that we
              develop software solutions that are perfectly aligned with your
              business processes. By involving clients in every step of the
              development process, we create solutions that truly address their
              challenges.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>UI Design:</b>Design visually appealing user interfaces that
              align with your brand identity, enhance usability, and deliver a
              seamless user experience.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>UX Design: </b>Focus on enhancing user satisfaction by
              optimizing navigation, information architecture, and overall
              usability of digital products.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Usability Testing: </b>Conduct usability testing to gather
              feedback, identify usability issues, and make iterative
              improvements to the design.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/* PORTFOLIO START HERE  */}
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row justify-content-between pb-5">
        <div className="col-md-6 col-lg-6 heading-section heading-section-white ">
          <span className="subheading">Recent Portfolio</span>
          <h2 style={{ color: "black" }}>Our Recent Projects</h2>
        </div>
        <div className="col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
          <Link to="../portfolio/ui-ux-designing" className="btn-custom">
            View All Projects <span className="fa fa-chevron-right" />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 ">
          <div
            className="project-wrap img d-flex align-items-end"
            style={{ backgroundImage: `url(${YCC})` }}
          >
            <div className="text">
              <span>Yogyata Coaching Centre</span>
              <h3>
                <Link to="../portfolio/ycc-web-app">Interior Design</Link>
              </h3>
              <Link
                to="../portfolio/ycc-web-app"
                className="icon d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div
            className="project-wrap img d-flex align-items-end"
            style={{ backgroundImage: `url(${YCC_Mobileapp})` }}
          >
            <div className="text">
              <span>Yogyata Coaching Centre</span>
              <h3>
                <Link to="../portfolio/ycc-mobile-app">Interior Design</Link>
              </h3>
              <Link
                to="../portfolio/ycc-mobile-app"
                className="icon d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div
            className="project-wrap img d-flex align-items-end"
            style={{ backgroundImage: `url(${Legalstix})` }}
          >
            <div className="text">
              <span>Leads CRM</span>
              <h3>
                <Link to="../portfolio/leads-management-system">Interior Design</Link>
              </h3>
              <Link
                to="../portfolio/leads-management-system"
                className="icon d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* PORTFOLIO SECTION END HERE  */}
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-7 heading-section ">
          <span className="subheading">Tech Stack</span>
          <h2>Technologies Used Are</h2>
        </div>
      </div>
    </div>
    <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
      <span className="flaticon-html" />
      <span className="flaticon-css" />
      <span className="flaticon-figma" />
      <span className="flaticon-adobe" />
      <span className="flaticon-adobe-photoshop" />
    </div>
    {/*?php include("./components/servicesNav.php") ?*/}
    {/*?php include("./components/contact.php") ?*/}
  </section>
  {/*?php
  include("./components/footer.php");
?*/}
</>

}

export default UiUxDesigningDev;