import { Link } from "react-router-dom";
import contact from "../images/contact.webp"





const Contact2=()=>{
return <>
{/*?php include("./components/header.php") ?*/}
<section
  className="hero-wrap hero-wrap-2"
  style={{ backgroundImage: `url(${contact})` }}
>
  <div className="overlay" />
  <div className="container">
    <div className="row no-gutters slider-text align-items-end">
      <div className="col-md-9 ftco-animate pb-5">
        <p className="breadcrumbs">
          <span className="mr-2">
            <Link to="/">
              Home <i className="fa fa-chevron-right" />
            </Link>
          </span>{" "}
          <span>
            Contact us <i className="fa fa-chevron-right" />
          </span>
        </p>
        <h1 className="mb-0 bread">Contact us</h1>
      </div>
    </div>
  </div>
</section>
<section className="ftco-section ftco-no-pb">
  <div className="overlay" />
  <div className="container">
    <div className="row">
      <div className="col-md-12 heading-section ">
        <span className="subheading">Contact Us</span>
        <h2>Contact MCodez IT Solutions Today</h2>
        <p>
          Thank you for considering MCodez IT Solutions for your digital
          needs. Whether you have a project in mind, need more information
          about our services, or simply want to say hello, we'd love to hear
          from you. Get in touch with us through the following channels:
        </p>
      </div>
    </div>
  </div>
</section>
<section className="ftco-section">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="wrapper">
          <div className="row no-gutters">
            <div className="col-md-7 d-flex align-items-stretch">
              <div className="contact-wrap w-100 p-md-5 p-4">
                <h3 className="mb-4">Get in touch</h3>
                <form
                  method="POST"
                  id="contactForm"
                  name="contactForm"
                  className="contactForm"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          id="message"
                          cols={30}
                          rows={7}
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="submit"
                          defaultValue="Send Message"
                          className="btn btn-primary"
                        />
                        <div className="submitting" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-5 d-flex align-items-stretch">
              <div className="info-wrap bg-darken w-100 p-lg-5 p-4">
                <h3 className="mb-4 mt-md-4">Contact us</h3>
                <div className="dbox w-100 d-flex align-items-start">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-map-marker" />
                  </div>
                  <div className="text pl-3">
                    <p>
                      <span>Address:</span> 198 West 21th Street, Suite 721
                      New York NY 10016
                    </p>
                  </div>
                </div>
                <div className="dbox w-100 d-flex align-items-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-phone" />
                  </div>
                  <div className="text pl-3">
                    <p>
                      <span>Phone:</span>{" "}
                      <a href="tel:+91 7988337165">+91 7988337165</a>
                    </p>
                  </div>
                </div>
                <div className="dbox w-100 d-flex align-items-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-paper-plane" />
                  </div>
                  <div className="text pl-3">
                    <p>
                      <span>Email:</span>{" "}
                      <a href="mailto:contact@mcodez.com">
                        <span
                          className="__cf_email__"
                          data-cfemail="e0898e868fa0998f959293899485ce838f8d"
                        >
                          contact@mcodez.com
                        </span>
                      </a>
                    </p>
                  </div>
                </div>
                <div className="dbox w-100 d-flex align-items-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-globe" />
                  </div>
                  <div className="text pl-3">
                    <p>
                      <span>Website: </span> <a href="#">mcodez.com</a>
                    </p>
                  </div>
                </div>
                <div className="dbox w-100 d-flex align-items-center">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa-brands fa-whatsapp" />
                  </div>
                  <div className="text pl-3">
                    <p>
                      <span>WhatsApp: </span>
                      <a
                        className=""
                        href="https://api.whatsapp.com/send?phone=917988337165&text=Can i get more info about your services?"
                        target="_blank"
                      >
                        Click To Send Message
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/*?php include("./components/footer.php") ?*/}
</>

}

export default Contact2;