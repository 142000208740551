
import legalstix from "../projects/Legalstix-CRM-SD.webp"
import web2 from "../images/web2.webp"
import Contact from "./Contact";
import { Link } from "react-router-dom";


const LeadManagementSystem=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${web2})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <a href="/">
                  Portfolio <i className="fa fa-chevron-right" />
                </a>
              </span>{" "}
              <span>
                Leads Management System <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">Leads Management System</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                MCodez IT Solutions proudly presents the Levnext Projects CRM, a
                comprehensive customer relationship management platform designed
                to enhance business operations and client interactions. This
                platform offers robust tools for managing client information,
                tracking sales, and optimizing customer engagement strategies.
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Website Application</span>
          <h2>Leads Management System</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img
                src={legalstix}
                alt=""
                className="img-fluid"
              />
            </p>
            <span className="portfolio-category">Website Application</span>
            <h2 className="mb-3 heading-title">Leads Management System</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Secure Login: </b>Role-based access ensuring secure and
                customized access for different users including admins, sales
                representatives, and support staff.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Live Classes: </b>Integration of live class schedules with easy
                access for students to join sessions, fostering an interactive
                learning environment.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Client Management:</b>Efficient management of client data with
                detailed profiles, interaction history, and communication logs.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Sales Tracking:</b> Tools for tracking sales processes,
                managing leads, and monitoring performance metrics in realtime.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>User-Friendly Interface: </b>Intuitive dashboard providing
                comprehensive insights and easy navigation for managing client
                relationships and sales pipelines.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
              <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      <i>
                        "The CRM developed by MCodez IT Solutions has streamlined
                        our customer management processes, making it easier to
                        track interactions and manage sales. The platform is
                        user-friendly and meets all our business needs.""
                      </i>
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">– Administrator</p>
                        <span className="position" />
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-mongodb" />
        <span className="flaticon-express" />
        <span className="flaticon-react" />
        <span className="flaticon-node" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
      <Contact/>
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}
export default LeadManagementSystem;