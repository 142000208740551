import { Link } from "react-router-dom";

const Contact=()=>{


    return <section className="ftco-section ftco-no-pb">
    <div className="container">
      <div className="row">
        <div className="overlay" />
        <div className="col-md-12" style={{ textAlign: "center" }}>
          <div className="same-app">
            <h1>Do you want your mobile app developed?</h1>
            <Link
              className="btn btn-primary"
              to="/contact-us"
              style={{ padding: 17 }}
            >
              Click here to Contact Us
            </Link>
            <span>or</span>
            <h2>Reach us at</h2>
            <div className="reach-us">
              <a className="" href="mailto:contact@mcodez.com">
                contact@mcodez.com
              </a>
              <span>|</span>
              <a className="" href="tel:+91 79883 37165">
                +91 79883 37165
              </a>
              <span>|</span>
              <Link
                className=""
                href="https://api.whatsapp.com/send?phone=917988337165&text=Can i get more info about your services?"
                target="_blank"
              >
                Whatsapp
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

      
}


export default Contact;