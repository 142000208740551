import { Link } from "react-router-dom";
import bg_mobile from "../images/bg_mobile.webp";
import Admin_ma from "../projects/Admin-MA.webp";

const MySchoolAdminApp=()=>{

     return <>
     {/*?php
     include("./components/header.php");
   ?*/}
     <section
       className="hero-wrap hero-wrap-2"
       style={{ backgroundImage: `url("${bg_mobile}")` }}
     >
       <div className="overlay" />
       <div className="container">
         <div className="row no-gutters slider-text align-items-end">
           <div className="col-md-9  pb-5">
             <p className="breadcrumbs">
               <span className="mr-2">
                 <Link to="/">
                   Portfolio <i className="fa fa-chevron-right" />
                 </Link>
               </span>{" "}
               <span>
                 MySchool Admin App <i className="fa fa-chevron-right" />
               </span>
             </p>
             <h1 className="mb-0 bread">MySchool Admin App</h1>
             <p className="breadcrumbs">
               <span className="mr-2">
                 At MCodez IT Solutions, we specialize in creating tailored
                 solutions that enhance operational efficiency and user experience.
                 Our recent project involved the development of a comprehensive
                 Admin App designed specifically for educational institutions.{" "}
                 <i className="fa fa-chevron-right" />
               </span>
             </p>
           </div>
         </div>
       </div>
     </section>
     <div className="container py-4 mt-2">
       <div className="row justify-content-center mb-5">
         <div className="col-md-7 heading-section text-center">
           <span className="subheading">Mobile Application</span>
           <h2>MySchool Admin App</h2>
         </div>
       </div>
     </div>
     <div style={{ marginTop: "-10%" }} />
     <section className="ftco-section ftco-no-pt ftco-no-pb">
       <div className="container">
         <div className="row justify-content-center">
           <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
             <p style={{ textAlign: "center" }}>
               <img src={Admin_ma} alt="" className="img-fluid" />
             </p>
             <span className="portfolio-category">Mobile Application</span>
             <h2 className="mb-3 heading-title">MySchool Admin App</h2>
             <ul
               className="pricing-text mb-2 list-unstyled"
               style={{ color: "black" }}
             >
               <li>
                 <span className="fa fa-check-circle mr-2" />
                 <b>Secure Login:</b> Ensures only authorized personnel can access
                 the app with secure username and password entry
               </li>
               <li>
                 <span className="fa fa-check-circle mr-2" />
                 <b>Homework Management: </b>Facilitates easy tracking and
                 publishing of homework assignments. The dashboard provides a clear
                 overview of unpublished and published assignments, categorized by
                 class and in-charge teacher.
               </li>
               <li>
                 <span className="fa fa-check-circle mr-2" />
                 <b>User-Friendly Interface:</b> The app is designed with a simple,
                 intuitive interface, ensuring ease of use for all staff members.
               </li>
               <li>
                 <span className="fa fa-check-circle mr-2" />
                 <b>Real-Time Updates:</b> Administrators can publish and update
                 homework assignments in real-time, keeping students and parents
                 informed promptly.
               </li>
             </ul>
             <div className="tag-widget post-tag-container mb-5 mt-5">
               <div className="tagcloud">
               <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                 <a href="#" className="tag-cloud-link">
                   Digital Marketing
                 </a>
               </div>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section className="ftco-section testimony-section ftco-no-pt bg-light">
       <div className="overlay" />
       <div className="container">
         <div className="row pb-4">
           <div className="col-md-7 heading-section ">
             <span className="subheading" style={{ marginTop: "10%" }}>
               Testimonial
             </span>
             <h2 className="mb-4">
               People Say <br />
               About This Work
             </h2>
           </div>
         </div>
       </div>
       <div className="container-fluid px-lg-5">
         <div className="row ">
           <div className="col-md-12">
             <div className="card" style={{ borderRadius: 30 }}>
               <div className="item">
                 <d className="testimony-wrap py-4">
                   <div className="text">
                     <p className="mb-4">
                       <i>
                         "Working with MCodez IT Solutions has been an absolute
                         pleasure. Their expertise and dedication are evident in
                         the high-quality Admin App they developed for us. The app
                         has significantly improved our administrative efficiency
                         and enhanced communication across our institution. The
                         team was responsive to our needs and delivered the project
                         on time. We highly recommend MCodez IT Solutions for their
                         exceptional service and technical prowess."
                       </i>
                     </p>
                     <div className="d-flex align-items-center">
                       <div
                         className="user-img"
                         style={{ backgroundImage: "url(images/person_1.jpg)" }}
                       />
                       <div className="pl-3">
                         <p className="star">
                           <span className="fa fa-star" />
                           <span className="fa fa-star" />
                           <span className="fa fa-star" />
                           <span className="fa fa-star" />
                           <span className="fa fa-star-o" />
                         </p>
                         <p className="name">My School Administration</p>
                         <span className="position">Marketing Manager</span>
                       </div>
                     </div>
                   </div>
                 </d>
               </div>
             </div>
           </div>
         </div>
       </div>
     </section>
     <section className="ftco-section ftco-no-pb">
       <div className="overlay" />
       <div className="container">
         <div className="row">
           <div className="col-md-7 heading-section ">
             <span className="subheading">Tech Stack</span>
             <h2>Technologies Used Are</h2>
           </div>
         </div>
       </div>
       <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
         <span className="flaticon-php" />
         <span className="flaticon-mysql" />
         <span className="flaticon-flutter" />
         <span className="flaticon-laravel" />
         <span className="flaticon-dart" />
         <span className="flaticon-firebase" />
       </div>
       {/*?php include("./components/contact.php") ?*/}
     </section>
     {/*?php
     include("./components/footer.php");
   ?*/}
   </>
   
}


export default MySchoolAdminApp;