

import { Link } from 'react-router-dom';
const Footer=()=>{
    return <>
  <section className="ftco-intro ftco-section ftco-no-pb">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12 text-center">
          <div
            className="img"
            style={{ backgroundImage: "url(images/bg_4.jpg)" }}
          >
            <div className="overlay" />
            <h2>Join Us Newsletter</h2>
            <p>Sign Up to our Newsletter and get our latest news update</p>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <form action="#" className="subscribe-form">
                  <div className="form-group d-flex">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter email address"
                    />
                    <input
                      type="submit"
                      defaultValue="Subscribe"
                      className="submit px-3"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* FOOTER START HERE  */}
  <footer className="ftco-footer">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2 logo">
              <a href="#">MCodez IT Solutions</a>
            </h2>
            <p>EMPOWERING INNOVATOIN</p>
            <ul className="ftco-footer-social list-unstyled mt-2">
              <li className="">
                <Link to="https://twitter.com/MCodezsolution" target="_blank">
                  <span className="fa-brands fa-x" />
                </Link>
              </li>
              <li className="">
                <Link
                  to="https://www.facebook.com/mcodezitsolutionskarnal/"
                  target="_blank"
                >
                  <span className="fa-brands fa-facebook-f" />
                </Link>
              </li>
              <li className="">
                <Link
                  to="https://www.instagram.com/MCodez_it_solutions/"
                  target="_blank"
                >
                  <span className="fa-brands fa-instagram" />
                </Link>
              </li>
              <li className="">
                <Link
                  to="https://www.linkedin.com/company/MCodez-it-solutions/"
                  target="_blank"
                >
                  <span className="fa-brands fa-linkedin" />
                </Link>
              </li>
              <li className="">
                <Link
                  to="https://www.youtube.com/@MCodez_IT_Solutions/featured"
                  target="_blank"
                >
                  <span className="fa-brands fa-youtube" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-12 col-md">
          <div className="ftco-footer-widget mb-4 ml-md-4">
            <h2 className="ftco-heading-2">Explore</h2>
            <ul className="list-unstyled">
              <li>
                <Link to="/about">
                  <span className="fa fa-chevron-right mr-2" />
                  About
                </Link>
              </li>
              <li>
                <Link to="/contact-us">
                  <span className="fa fa-chevron-right mr-2" />
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/portfolio">
                  <span className="fa fa-chevron-right mr-2" />
                  Portfolio
                </Link>
              </li>
              <li>
                <Link to="/blogs">
                  <span className="fa fa-chevron-right mr-2" />
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-12 col-md">
          <div className="ftco-footer-widget mb-4 ml-md-4">
            <h2 className="ftco-heading-2">Info</h2>
            <ul className="list-unstyled">
              <li>
                <Link to="/about">
                  <span className="fa fa-chevron-right mr-2" />
                  Join us
                </Link>
              </li>
              <li>
                <Link to="/blogs">
                  <span className="fa fa-chevron-right mr-2" />
                  Blog
                </Link>
              </li>
              <li>
                <a href="#">
                  <span className="fa fa-chevron-right mr-2" />
                  Privacy &amp; Policy
                </a>
              </li>
              <li>
                <a href="#">
                  <span className="fa fa-chevron-right mr-2" />
                  Term &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-12 col-md">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Company</h2>
            <ul className="list-unstyled">
              <li>
                <Link to="/about">
                  <span className="fa fa-chevron-right mr-2" />
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/blogs">
                  <span className="fa fa-chevron-right mr-2" />
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact-us">
                  <span className="fa fa-chevron-right mr-2" />
                  Contact
                </Link>
              </li>
              <li>
                <a href="#">
                  <span className="fa fa-chevron-right mr-2" />
                  Careers
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-sm-12 col-md">
          <div className="ftco-footer-widget mb-4">
            <h2 className="ftco-heading-2">Have a Questions?</h2>
            <div className="block-23 mb-3">
              <ul>
                <li>
                  <span className="icon fa fa-map marker" />
                  <span className="text">
                    24, Vikas Nagar, Sector 6, Karnal, Haryana 132001, India
                  </span>
                </li>
                <li>
                  <Link to="tel:+91 7988337165">
                    <span className="icon fa fa-phone" />
                    <span className="text">+91 7988337165</span>
                  </Link>
                </li>
                <li>
                  <Link to="mailto:contact@mcodez.com">
                    <span className="icon fa fa-paper-plane pr-4" />
                    <span className="text">
                      <span className="__cf_email__">contact@mcodez.com</span>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid px-0 py-5 bg-black">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="mb-0" style={{ color: "rgba(255,255,255,.5)" }}>
              Copyright ©2020-2024 MCodez IT Solutions All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* <div id="ftco-loader" class="show fullscreen"><svg class="circular" width="48px" height="48px"><circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" /><circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" /></svg></div> */}
</>

}

export default Footer;