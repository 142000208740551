import Contact from "./Contact";
import web2 from '../images/web2.webp'
import DrAshok from "../projects/Dr.Ashok-Ecom.webp"
import { Link } from "react-router-dom";



const DrAshokEcommerce=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${web2})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link href="/portfolio">
                  Portfolio <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                Dr.Ashok Ecommerce <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">Dr. Ashok Ecommerce</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                At MCodez IT Solutions, we specialize in creating robust
                e-commerce platforms that cater to unique client needs. One of our
                premier projects is the development of an online store for Dr.
                Ashok Virility, a center for sexual natural medicine and
                treatment.
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Website Application</span>
          <h2>Dr. Ashok Ecommerce</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img
                src={DrAshok}
                alt=""
                className="img-fluid"
              />
            </p>
            <span className="portfolio-category">Mobile Application</span>
            <h2 className="mb-3 heading-title">Dr. Ashok Ecommerce</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>User-friendly Interface:</b>: Simplified navigation for easy
                product browsing and purchasing.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Secure Transactions: </b>Robust security measures to ensure
                safe online transactions.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Product Listings:</b> Detailed product descriptions and clear
                categorization for easy discovery.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Consultation Requests: </b> Easy access to request
                consultations for various treatments.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>TCustomer Support: </b> 24/7 customer support for inquiries and
                assistance.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
              <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      <i>
                        "MCodez IT Solutions transformed our business with their
                        exceptional e-commerce solutions. Their expertise in web
                        development and digital marketing has significantly
                        increased our online sales and customer engagement. We
                        highly recommend their services."
                      </i>
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">Dr. Ashok</p>
                        <span className="position">
                          Founder of Dr. Ashok Virility
                        </span>
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-php" />
        <span className="flaticon-mysql" />
        <span className="flaticon-laravel" />
        <span className="flaticon-bootstrap" />
        <span className="flaticon-jquery" />
        <span className="flaticon-javascript" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
      <Contact/>
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}

export default DrAshokEcommerce;