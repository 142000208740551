import { Link } from "react-router-dom";



const ServiceNav=()=>{
    return <>
    
<section className="ftco-section ftco-no-pb">
  <div className="overlay"></div>
  <div className="container">
  <div className="row">
    <div class="col-md-12 heading-section ">
        <span className="subheading">Other Services</span>
        <h2>EMPOWERING INNOVATION</h2>
        <div className="tag-widget post-tag-container mb-5 mt-5">
        <div className="tagcloud">
        <Link to="../services/web-development"  className="tag-cloud-link">Web Development</Link>
        <Link to="../service/mobile-app-development.php" className="tag-cloud-link">Mobile App Development</Link>
        <Link to="../services/custom-software-development" className="tag-cloud-link ">Custom Software Development</Link>
        <Link to="../services/social-media-management" className="tag-cloud-link ">Social Media Management</Link>
        <Link to="../services/uiux-design-dev" className="tag-cloud-link ">UI-UX Designing</Link>
        <a href="#" className="tag-cloud-link">Digital Marketing</a>
        </div>
        </div>
    </div>
  </div>
  </div>

</section>
</>
  
}


export default ServiceNav;