import smm from "../images/smm.webp"
import ServiceNav from "./ServiceNav"
import Contact from "./Contact";
import { Link } from "react-router-dom";
import customSoftware from "../images/custom-software-1.webp"



const SocialMediaComponent=()=>{
   return <>
   {/*?php
   include("./components/header.php");
 ?*/}
   <section
     className="hero-wrap hero-wrap-2"
     style={{ backgroundImage: `url(${smm})` }}
   >
     <div className="overlay" />
     <div className="container">
       <div className="row no-gutters slider-text align-items-end">
         <div className="col-md-9  pb-5">
           <p className="breadcrumbs">
             <span className="mr-2">
               <Link to="/">
                 Home <i className="fa fa-chevron-right" />
               </Link>
               &nbsp;
               <Link to="/services">
                 Services <i className="fa fa-chevron-right" />
               </Link>
             </span>{" "}
             <span>Social Media Management </span>
           </p>
           <h1 className="mb-0 bread">Social Media Management</h1>
           {/* <p class="breadcrumbs"><span class="mr-2">Our mobile app development services cover a wide spectrum, including Android app development, iOS app development, and cross-platform app development. We leverage the latest technologies and industry best practices to create intuitive, engaging, and high-performing mobile applications that resonate with your target audience.</span></p> */}
         </div>
       </div>
     </div>
   </section>
   <section className="ftco-section ftco-no-pb">
     <div className="overlay" />
     <div className="container">
       <div className="row">
         <div className="col-md-7 heading-section ">
           <span className="subheading">Social Media Management</span>
           <h2>EMPOWERING INNOVATION</h2>
           <p>
             In today's digital era, your online presence is more than just a
             necessity—it's a powerful opportunity to engage with your audience,
             build brand loyalty, and drive business growth. At MCodez IT
             Solution, we specialize in Social Media Optimization (SMO) to help
             you harness the full potential of social media platforms and stand
             out in a crowded marketplace.
           </p>
         </div>
         <div className="col-md-5 ">
           <img
             src={customSoftware}
             width="100%"
             height="auto"
             alt="Animated GIF"
           />
         </div>
       </div>
     </div>
   </section>
   <section className="ftco-section ftco-no-pb">
     <div className="overlay" />
     <div className="container">
       <div className="row">
         <div className="col-md-12 heading-section ">
           <span className="subheading">Social Media Optimization</span>
           <h2>Our Social Media Optimization include</h2>
           <ul
             className="pricing-text mb-2 list-unstyled"
             style={{ color: "black" }}
           >
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Flexible Subscription Plans: </b> We offer two subscription
               plans to fit different business needs: Alpha at Rs. 5,000 per
               month and Beta at Rs. 10,000 per month. These plans are designed
               to provide you with the flexibility to choose the level of service
               that best suits your budget and goals. Both plans are crafted to
               maximize your social media presence and engagement.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Comprehensive Account Management: </b>Our SMO services include
               managing five key social media platforms: Instagram, Facebook,
               YouTube, Twitter, and LinkedIn. By covering these major platforms,
               we ensure that your brand reaches a wide and diverse audience. Our
               expert team manages all aspects of these accounts to maintain a
               consistent and professional online presence.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Consistent and Engaging Posting:</b>We create and post 4-8
               high-quality posts per week to keep your audience engaged and
               informed. Our content creation focuses on relevance and
               engagement, tailored to resonate with your target audience. This
               consistent posting schedule helps to maintain and grow your social
               media presence.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Enhanced Visibility: </b>Our SMO strategies are designed to
               enhance your brand's visibility across social media platforms. We
               use proven techniques to increase your reach and ensure your
               content is seen by as many people as possible. Enhanced visibility
               helps attract new followers and potential customers to your brand.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Engaging Content Creation: </b>Creating engaging content is at
               the heart of our SMO services. Our team develops creative and
               interactive posts that capture your audience's attention and
               encourage interaction. Engaging content is crucial for building a
               loyal and active online community.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Audience Growth: </b> We focus on strategies that drive
               audience growth by attracting new followers and retaining existing
               ones. Our approach includes targeted campaigns and interactive
               content that appeal to your ideal audience. Growing your audience
               expands your brand’s reach and influence.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Brand Loyalty and Trust: </b>Building brand loyalty and trust
               is a key objective of our SMO services. We create content and
               manage interactions that reflect your brand's values and foster a
               positive relationship with your audience. Trust and loyalty lead
               to long-term customer relationships and increased brand advocacy.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Calendar Management System: </b>We use a sophisticated calendar
               management system to plan and schedule your social media posts.
               This ensures that your content is posted at optimal times for
               maximum engagement. It also allows for advanced planning and
               coordination of social media campaigns.
             </li>
             <li>
               <span className="fa fa-check-circle mr-2" />
               <b>Performance Tracking and Reporting: </b>We provide detailed
               performance tracking and reporting to keep you informed of your
               social media progress. Our reports include insights and analytics
               that measure the effectiveness of your SMO efforts. This
               data-driven approach allows us to continuously refine and improve
               your social media strategy.
             </li>
           </ul>
         </div>
       </div>
     </div>
   </section>
   {/* PORTFOLIO START HERE  */}
   {/* <section class="ftco-section ftco-no-pb">
 <div class="overlay"></div>
 <div class="container">
 <div class="row justify-content-between pb-5">
 <div class="col-md-6 col-lg-6 heading-section heading-section-white ">
 <span class="subheading">Recent Portfolio</span>
 <h2 style="color:black">Our Recent Projects</h2>
 </div>
 <div class="col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
 <a href="./ui-ux-designing.php" class="btn-custom">View All Projects <span class="fa fa-chevron-right"></span></a>
 </div>
 </div>
 <div class="row">
 <div class="col-md-4 ">
 <div class="project-wrap img d-flex align-items-end" style="background-image: url(projects/YCC-SD.webp);">
 <div class="text">
 <span>Yogyata Coaching Centre</span>
 <h3><a href="./ycc-app-app.php">Interior Design</a></h3>
 <a href="./ycc-web-app.php" class="icon d-flex align-items-center justify-content-center"><span class="fa fa-chevron-right"></span></a>
 </div>
 </div>
 </div>
 <div class="col-md-4 ">
 <div class="project-wrap img d-flex align-items-end" style="background-image: url(projects/YCC_Mobileapp.webp);">
 <div class="text">
 <span>Yogyata Coaching Centre</span>
 <h3><a href="./ycc-mobile-app.php">Interior Design</a></h3>
 <a href="./ycc-mobile-app.php" class="icon d-flex align-items-center justify-content-center"><span class="fa fa-chevron-right"></span></a>
 </div>
 </div>
 </div>
 <div class="col-md-4 ">
 <div class="project-wrap img d-flex align-items-end" style="background-image: url(projects/Legalstix-CRM-SD.webp);">
 <div class="text">
 <span>Leads CRM</span>
 <h3><a href="./leads-management-system.php">Interior Design</a></h3>
 <a href="./leads-management-system.php" class="icon d-flex align-items-center justify-content-center"><span class="fa fa-chevron-right"></span></a>
 </div>
 </div>
 </div>
 </div>
 </div>
 </section> */}
   {/* PORTFOLIO SECTION END HERE  */}
   <section className="ftco-section ftco-no-pb">
     {/* <div class="overlay"></div>
   <div class="container">
   <div class="row">
   <div class="col-md-7 heading-section ">
   <span class="subheading">Tech Stack</span>
   <h2>Technologies Used Are</h2>
   </div>
   </div>
   </div>
         <div class="col-md-12 mt-2 py-4" style="text-align:center">
             <span class="flaticon-node"></span>
             <span class="flaticon-mysql"></span>
             <span class="flaticon-mongodb"></span>
             <span class="flaticon-react"></span>
             <span class="flaticon-php"></span>
             <span class="flaticon-laravel"></span>
             <span class="flaticon-javascript"></span>
             <span class="flaticon-jquery"></span>
         </div>
       </div>
 
   </div>
   </div>
     </div>
   </div> */}
     {/*?php include("./components/servicesNav.php") ?*/}
     <ServiceNav/>
     {/*?php include("./components/contact.php") ?*/}
     <Contact/>
   </section>
   {/*?php
   include("./components/footer.php");
 ?*/}
 </>
 
}

export default SocialMediaComponent