import bg_mobile from "../images/bg_mobile.webp"
import bg_mobileApp from "../images/mobileApp_service-1.webp";
import ycc_mobile_app from "../projects/YCC_Mobileapp.webp";
import admin_mobile_app from "../projects/Admin-MA.webp";
import staff_mobile_app from "../projects/Staff-MA.webp";
import { Link } from "react-router-dom";


const MobileAppDevelopment=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url("${bg_mobile}")` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/">
                  Home <i className="fa fa-chevron-right" />
                </Link>
                &nbsp;
                <a href="services.php">
                  Services <i className="fa fa-chevron-right" />
                </a>
              </span>{" "}
              <span>Mobile App Development </span>
            </p>
            <h1 className="mb-0 bread">Mobile App Development</h1>
            {/* <p class="breadcrumbs"><span class="mr-2">Our mobile app development services cover a wide spectrum, including Android app development, iOS app development, and cross-platform app development. We leverage the latest technologies and industry best practices to create intuitive, engaging, and high-performing mobile applications that resonate with your target audience.</span></p> */}
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Mobile App Development</span>
            <h2>EMPOWERING INNOVATION</h2>
            <p>
              Mobile apps have become an integral part of modern business
              strategies, offering a direct channel for customer engagement and
              brand visibility. At MCodez IT Solutions, we specialize in creating
              custom mobile applications tailored to meet your specific business
              needs and objectives. Our mobile app development process begins with
              a thorough understanding of your requirements, target audience, and
              business goals. We then leverage the latest technologies and
              industry best practices to design, develop, and deploy intuitive and
              feature-rich mobile apps for iOS and Android platforms. From UI/UX
              design to backend development and testing, we ensure that your
              mobile app not only looks great but also delivers seamless
              functionality and an exceptional user experience.
            </p>
          </div>
          <div className="col-md-5">
            <img
              src={bg_mobileApp}
              width="100%"
              alt="Animated GIF"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 heading-section ">
            <span className="subheading">Mobile App Development</span>
            <h2>Our Mobile App Development Process</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Understanding Your Requirements: </b>We begin with a thorough
                analysis of your requirements to ensure that we fully understand
                your business needs. This involves identifying your target
                audience and business goals to tailor the app accordingly. Our
                goal is to create an app that not only meets but exceeds your
                expectations.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Leveraging Latest Technologies: </b>At MCodez IT Solutions, we
                use cutting-edge technologies and industry best practices to
                develop mobile apps. This ensures that the apps we create are not
                only modern and feature-rich but also reliable and scalable. By
                staying up-to-date with technological advancements, we provide our
                clients with innovative solutions.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>UI/UX Design:</b>Our design team focuses on creating visually
                appealing and user-friendly interfaces. We understand the
                importance of a seamless user experience and ensure that our
                designs facilitate easy navigation. The result is an app that
                looks great and provides an enjoyable user experience.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Backend Development: </b> Robust backend development is crucial
                for the functionality of any mobile app. Our team ensures that the
                backend is well-structured and integrates smoothly with databases
                and third-party services. This guarantees that your app functions
                reliably and efficiently, even under heavy usage.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Testing and Deployment: </b>We conduct rigorous testing to
                ensure that your app is bug-free and performs smoothly. This
                includes both automated and manual testing to catch any issues
                before deployment. Once the app is thoroughly tested, we handle
                the deployment process, ensuring a smooth launch on relevant app
                stores.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Exceptional User Experience: </b>Our apps are designed to
                provide an exceptional user experience, which is key to user
                retention and satisfaction. We focus on creating engaging,
                responsive, and reliable apps that meet user needs. The ultimate
                goal is to ensure that users have a positive interaction with your
                app every time they use it.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    {/* PORTFOLIO START HERE  */}
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row justify-content-between pb-5">
          <div className="col-md-6 col-lg-6 heading-section heading-section-white ">
            <span className="subheading">Recent Portfolio</span>
            <h2 style={{ color: "black" }}>Our Recent Projects</h2>
          </div>
          <div className="col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
            <Link to="../portfolio/mobile-application-development" className="btn-custom">
              View All Projects <span className="fa fa-chevron-right" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${ycc_mobile_app})` }}
            >
              <div className="text">
                <span>Yogyata Coaching Centre</span>
                <h3>
                  <Link to="../portfolio/ycc-mobile-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/ycc-mobile-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${admin_mobile_app})` }}
            >
              <div className="text">
                <span>My School Admin App</span>
                <h3>
                  <Link to="../portfolio/myschool-admin-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/myschool-admin-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${staff_mobile_app})` }}
            >
              <div className="text">
                <span>My School Staff App</span>
                <h3>
                  <Link tp="../portfolio/myschool-staff-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/myschool-staff-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* PORTFOLIO SECTION END HERE  */}
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-node" />
        <span className="flaticon-mysql" />
        <span className="flaticon-mongodb" />
        <span className="flaticon-react" />
        <span className="flaticon-flutter" />
        <span className="flaticon-firebase" />
        <span className="flaticon-dart" />
      </div>
      {/*?php include("./components/servicesNav.php") ?*/}
      {/*?php include("./components/contact.php") ?*/}
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
  
}


export default MobileAppDevelopment;