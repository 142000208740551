
import Contact2 from "./Contact2";
import web2 from "../images/web2.webp";
import PW from "../projects/Propoint-SD.webp"
import Contact from "./Contact";
import { Link } from "react-router-dom";

const ProPointWebApp=()=>{
  return <>
  {/*?php
  include("./components/header.php");
?*/}
  <section
    className="hero-wrap hero-wrap-2"
    style={{ backgroundImage: `url(${web2})` }}
  >
    <div className="overlay" />
    <div className="container">
      <div className="row no-gutters slider-text align-items-end">
        <div className="col-md-9  pb-5">
          <p className="breadcrumbs">
            <span className="mr-2">
              <Link to="/">
                Portfolio <i className="fa fa-chevron-right" />
              </Link>
            </span>{" "}
            <span>
              ProPoint Web App <i className="fa fa-chevron-right" />
            </span>
          </p>
          <h1 className="mb-0 bread">ProPoint Web App</h1>
          <p className="breadcrumbs">
            <span className="mr-2">
              At MCodez IT Solutions, we collaborate with industry leaders to
              create innovative digital solutions that streamline and enhance
              business processes. One of our premier projects is ProPoint, a
              comprehensive recruitment platform designed to connect businesses
              with top-tier talent efficiently and effectively.
              <i className="fa fa-chevron-right" />
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
  <div className="container py-4 mt-2">
    <div className="row justify-content-center mb-5">
      <div className="col-md-7 heading-section text-center">
        <span className="subheading">Website Application</span>
        <h2>ProPoint Web App</h2>
      </div>
    </div>
  </div>
  <div style={{ marginTop: "-10%" }} />
  <section className="ftco-section ftco-no-pt ftco-no-pb">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
          <p style={{ textAlign: "center" }}>
            <img
              src={PW}
              alt=""
              className="img-fluid"
            />
          </p>
          <span className="portfolio-category">Mobile Application</span>
          <h2 className="mb-3 heading-title">ProPoint Web App</h2>
          <ul
            className="pricing-text mb-2 list-unstyled"
            style={{ color: "black" }}
          >
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Power of Connection: </b> : ProPoint leverages a vast network
              of supplier agencies to find the best candidates for various
              business needs.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Hassle-Free Recruitment:</b>The platform's seamless process
              saves time and effort by taking the stress out of finding the
              right employees.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Real-Time Tracking:</b>A revolutionary SPN tool keeps clients
              updated on recruitment progress, enabling informed
              decision-making.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Comprehensive Approach:</b>Built on years of domestic and
              international experience to address the need for efficient
              recruitment.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Industry Expertise:</b> Built on years of domestic and
              international experience to address the need for efficient
              recruitment.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Strong Partnerships: </b> Robust network connections allow for
              tailored solutions that meet specific business requirements.
            </li>
          </ul>
          <div className="tag-widget post-tag-container mb-5 mt-5">
            <div className="tagcloud">
            <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
              <a href="#" className="tag-cloud-link">
                Digital Marketing
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section testimony-section ftco-no-pt bg-light">
    <div className="overlay" />
    <div className="container">
      <div className="row pb-4">
        <div className="col-md-7 heading-section ">
          <span className="subheading" style={{ marginTop: "10%" }}>
            Testimonial
          </span>
          <h2 className="mb-4">
            People Say <br />
            About This Work
          </h2>
        </div>
      </div>
    </div>
    <div className="container-fluid px-lg-5">
      <div className="row ">
        <div className="col-md-12">
          <div className="card" style={{ borderRadius: 30 }}>
            <div className="item">
              <d className="testimony-wrap py-4">
                <div className="text">
                  <p className="mb-4">
                    <i>
                      "ProPoint has transformed our recruitment process with
                      their innovative platform. The ease of use and real-time
                      updates have made managing our hiring needs incredibly
                      efficient. MCodez IT Solutions' expertise and dedication
                      to creating a tailored solution were evident throughout
                      the project. We highly recommend their services."
                    </i>
                  </p>
                  <div className="d-flex align-items-center">
                    <div
                      className="user-img"
                      style={{ backgroundImage: "url(images/person_1.jpg)" }}
                    />
                    <div className="pl-3">
                      <p className="star">
                        <span className="fa fa-star" />
                        <span className="fa fa-star" />
                        <span className="fa fa-star" />
                        <span className="fa fa-star" />
                        <span className="fa fa-star-o" />
                      </p>
                      <p className="name">-Mr. Karan Chavvra</p>
                      <span className="position">Founder of ProPoint</span>
                    </div>
                  </div>
                </div>
              </d>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-7 heading-section ">
          <span className="subheading">Tech Stack</span>
          <h2>Technologies Used Are</h2>
        </div>
      </div>
    </div>
    <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
      <span className="flaticon-php" />
      <span className="flaticon-mysql" />
      <span className="flaticon-laravel" />
      <span className="flaticon-bootstrap" />
      <span className="flaticon-jquery" />
      <span className="flaticon-javascript" />
    </div>
    {/*?php include("./components/contact.php") ?*/}
    <Contact/>
  </section>
  {/*?php
  include("./components/footer.php");
?*/}
</>

}


export default ProPointWebApp;