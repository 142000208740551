import blogs from "../images/blogs.webp"
import bg_web from "../images/bg_web2.webp"
import bgMobile from "../images/bg_mobile_2.webp"
import custom from "../images/customSoftwareDev.webp"
import software  from "../images/software.webp"
import ai from "../images/ai.webp"
import { Link } from "react-router-dom"

const Blogs=()=>{
    return <>
    
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${blogs})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9 ftco-animate pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <a href="index.html">
                  Home <i className="fa fa-chevron-right" />
                </a>
              </span>{" "}
              <span>
                Blogs <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">Blogs</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <div className="row d-flex">
              <div className="col-lg-6 ftco-animate">
                <div className="blog-entry">
                  <a
                    href="blog-single.html"
                    className="block-20"
                    style={{ backgroundImage: `url(${bg_web})` }}
                  ></a>
                  <div className="text d-block text-center">
                    <div className="meta">
                      <p>
                        <a href="#">
                          <span className="fa fa-calendar mr-2" />
                          Sept. 30, 2020
                        </a>
                        <a href="#">
                          <span className="fa fa-user mr-2" />
                          Admin
                        </a>
                        <a href="#" className="meta-chat">
                          <span className="fa fa-comment mr-2" /> 3
                        </a>
                      </p>
                    </div>
                    <h3 className="heading">
                      <a href="#">Applying your design principles</a>
                    </h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia...
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ftco-animate">
                <div className="blog-entry">
                  <a
                    href="blog-single.html"
                    className="block-20"
                    style={{ backgroundImage: `url(${bgMobile})` }}
                  ></a>
                  <div className="text d-block text-center">
                    <div className="meta">
                      <p>
                        <a href="#">
                          <span className="fa fa-calendar mr-2" />
                          Sept. 30, 2020
                        </a>
                        <a href="#">
                          <span className="fa fa-user mr-2" />
                          Admin
                        </a>
                        <a href="#" className="meta-chat">
                          <span className="fa fa-comment mr-2" /> 3
                        </a>
                      </p>
                    </div>
                    <h3 className="heading">
                      <a href="#">Applying your design principles</a>
                    </h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia...
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ftco-animate">
                <div className="blog-entry">
                  <a
                    href="blog-single.html"
                    className="block-20"
                    style={{
                      backgroundImage: `url(${custom})`
                    }}
                  ></a>
                  <div className="text d-block text-center">
                    <div className="meta">
                      <p>
                        <a href="#">
                          <span className="fa fa-calendar mr-2" />
                          Sept. 30, 2020
                        </a>
                        <a href="#">
                          <span className="fa fa-user mr-2" />
                          Admin
                        </a>
                        <a href="#" className="meta-chat">
                          <span className="fa fa-comment mr-2" /> 3
                        </a>
                      </p>
                    </div>
                    <h3 className="heading">
                      <a href="#">Applying your design principles</a>
                    </h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia...
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ftco-animate">
                <div className="blog-entry">
                  <a
                    href="blog-single.html"
                    className="block-20"
                    style={{ backgroundImage: `url(${software})` }}
                  ></a>
                  <div className="text d-block text-center">
                    <div className="meta">
                      <p>
                        <a href="#">
                          <span className="fa fa-calendar mr-2" />
                          Sept. 30, 2020
                        </a>
                        <a href="#">
                          <span className="fa fa-user mr-2" />
                          Admin
                        </a>
                        <a href="#" className="meta-chat">
                          <span className="fa fa-comment mr-2" /> 3
                        </a>
                      </p>
                    </div>
                    <h3 className="heading">
                      <a href="#">Applying your design principles</a>
                    </h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia...
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ftco-animate">
                <div className="blog-entry">
                  <a
                    href="blog-single.html"
                    className="block-20"
                    style={{ backgroundImage: `url(${ai})` }}
                  ></a>
                  <div className="text d-block text-center">
                    <div className="meta">
                      <p>
                        <a href="#">
                          <span className="fa fa-calendar mr-2" />
                          Sept. 30, 2020
                        </a>
                        <a href="#">
                          <span className="fa fa-user mr-2" />
                          Admin
                        </a>
                        <a href="#" className="meta-chat">
                          <span className="fa fa-comment mr-2" /> 3
                        </a>
                      </p>
                    </div>
                    <h3 className="heading">
                      <a href="#">Applying your design principles</a>
                    </h3>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia...
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <div className="block-27">
                  <ul>
                    <li>
                      <a href="#">&lt;</a>
                    </li>
                    <li className="active">
                      <span>1</span>
                    </li>
                    {/* <li><a href="#">2</a></li>
  <li><a href="#">3</a></li>
  <li><a href="#">4</a></li>
  <li><a href="#">5</a></li>*/}
                    <li>
                      <a href="#">&gt;</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-5 sidebar ftco-animate pl-md-5 mt-5 mt-md-0 pt-md-0 pt-5">
            <div className="sidebar-box bg-light rounded">
              <form action="#" className="search-form">
                <div className="form-group">
                  <span className="icon fa fa-search" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </form>
            </div>
            <div className="sidebar-box ftco-animate">
              <div className="categories">
                <h3>Services</h3>
                <li>
                  <Link to="../services/uiux-design-dev">UX/UI Design</Link>
                </li>
                <li>
                  <Link to="../services/web-development">Web Development</Link>
                </li>
                <li>
                  <Link to="../services/custom-software-development">
                    Custom Software Development
                  </Link>
                </li>
                <li>
                  <Link to="../service/mobile-app-development">
                    Mobile Application Development
                  </Link>
                </li>
                <li>
                  <Link to="../services/social-media-management">
                    Social Media Optimization
                  </Link>
                </li>
              </div>
            </div>
            <div className="sidebar-box ftco-animate">
              <h3>Recent Blog</h3>
              <div className="block-21 mb-4 d-flex justify-content-between">
                <a
                  className="blog-img"
                  style={{ backgroundImage: "url(images/image_1.jpg)" }}
                />
                <div className="text pl-3">
                  <h3 className="heading">
                    <a href="#">Marketing Strategies for Digital Ecosystem</a>
                  </h3>
                  <div className="meta">
                    <div>
                      <a href="#">
                        <span className="fa fa-calendar" /> Sept. 30, 2020
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <span className="fa fa-comment" /> 19
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-21 mb-4 d-flex justify-content-between">
                <a
                  className="blog-img"
                  style={{ backgroundImage: "url(images/ai.webp)" }}
                />
                <div className="text pl-3">
                  <h3 className="heading">
                    <a href="#">Marketing Strategies for Digital Ecosystem</a>
                  </h3>
                  <div className="meta">
                    <div>
                      <a href="#">
                        <span className="fa fa-calendar" /> Sept. 30, 2020
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <span className="fa fa-comment" /> 19
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block-21 mb-4 d-flex justify-content-between">
                <a
                  className="blog-img"
                  style={{ backgroundImage: "url(images/image_3.jpg)" }}
                />
                <div className="text pl-3">
                  <h3 className="heading">
                    <a href="#">Marketing Strategies for Digital Ecosystem</a>
                  </h3>
                  <div className="meta">
                    <div>
                      <a href="#">
                        <span className="fa fa-calendar" /> Sept. 30, 2020
                      </a>
                    </div>
                    <div>
                      <a href="#">
                        <span className="fa fa-comment" /> 19
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar-box ftco-animate">
              <h3>Categories</h3>
              <div className="tagcloud">
                <Link to="../services/uiux-design-dev" className="tag-cloud-link">
                  UX/UI
                </Link>
                <Link to="../services/web-development" className="tag-cloud-link">
                  Web
                </Link>
                <Link to="../services/custom-software-development" className="tag-cloud-link">
                  Software
                </Link>
                <Link to="../service/mobile-app-development.php" className="tag-cloud-link">
                  Mobile
                </Link>
                <Link
                  to="./services/social-media-management"
                  className="tag-cloud-link"
                >
                  SMO
                </Link>
              </div>
            </div>
            <div className="sidebar-box ftco-animate">
              <h3>Introduction</h3>
              <p>
                Welcome to the MCodez IT Solutions blog, your go-to resource for
                insightful articles, industry trends, and valuable tips on
                technology, digital transformation, and business innovation. Stay
                informed, inspired, and empowered with our latest updates and
                thought leadership content.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-php" />
        <span className="flaticon-mysql" />
        <span className="flaticon-laravel" />
        <span className="flaticon-flutter" />
        <span className="flaticon-dart" />
        <span className="flaticon-firebase" />
      </div>
      {/* Contact component ADD KARNA HA */}
      </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}
export default Blogs;