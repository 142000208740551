const UnderDev = () => {

    return (
        <>
            <div class="board">
               <h1 style={{textAlign:"center", margin:"100px 0 -50px 0"}}>Under Development</h1>
            </div>
        </>
    )
}

export default UnderDev;