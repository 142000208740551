
import bgMobile from '../images/bg_mobile.webp';
import Bright from "../projects/BrightWorld-MA.webp"
import ycc from "../projects/YCC_Mobileapp.webp"
import MA from "../projects/Codoc-MA.webp"
import Admin from "../projects/Admin-MA.webp"
import staff from "../projects/Staff-MA.webp"
import MySchoolMa from "../projects/MySchool-MA.webp"
import { Link } from 'react-router-dom';

const MobileApplicationDevelopment=()=>{

    return <>
    {/*?php include("components/header.php") ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${bgMobile})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/portfolio">
                  Portfolio <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                Mobile Application Development{" "}
                <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">Mobile Application Development</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-12 about-intro">
            <div className="row d-flex">
              <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
                <div
                  className="project-wrap img d-flex align-items-end"
                  style={{
                    backgroundImage: `url(${Bright})`,
                    borderRadius: 30
                  }}
                >
                  <div className="text">
                    <span>BrightWorld Mobile Application</span>
                    <h3>
                      <Link to="../portfolio/bright-world-mobile-app">Interior Design</Link>
                    </h3>
                    <Link
                      to="../portfolio/bright-world-mobile-app"
                      className="icon d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-2 pl-md-5 py-5">
                <div className="row justify-content-start pb-3 pt-md-5">
                  <div className="col-md-12 heading-section ">
                    <h2 className="mb-4">BrightWorld Mobile Application</h2>
                    <p>
                      MCodez IT Solutions is delighted to showcase the "Bright
                      World" app, a robust corporate communication tool designed
                      for Bright World Inc. This mobile application fosters
                      seamless communication within the company, enhancing
                      collaboration and information sharing among employees.
                    </p>
                  </div>
                </div>
                <div style={{ border: "2px solid black" }} />
              </div>
              {/* Section 1: Image > Content on Web, Content > Image on Mobile */}
              <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
                <div className="row justify-content-start pb-3 pt-md-5">
                  <div className="col-md-12 heading-section ">
                    <h2 className="mb-4">Yogyata Mobile Application</h2>
                    <p>
                      MCodez IT Solutions is proud to present the "Yogyata"
                      Student Science Dashboard, a comprehensive mobile
                      application designed to enhance the academic experience of
                      students. Developed for YCC School, this app serves as a
                      dynamic platform for students to monitor their academic
                      progress, attendance, and receive important notifications.
                    </p>
                  </div>
                </div>
                <div style={{ border: "2px solid black" }} />
              </div>
              <div className="col-md-6 order-1 order-md-2 d-flex align-items-stretch mt-2 py-2">
                <div
                  className="project-wrap img d-flex align-items-end"
                  style={{
                    backgroundImage: `url(${ycc})`,
                    borderRadius: 30
                  }}
                >
                  <div className="text">
                    <span>YCC Mobile Application</span>
                    <h3>
                      <Link to="/ycc-mobile-app">Interior Design</Link>
                    </h3>
                    <Link
                      to="/ycc-mobile-app"
                      className="icon d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Section 2: Content > Image on both Web and Mobile */}
              <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
                <div
                  className="project-wrap img d-flex align-items-end"
                  style={{
                    backgroundImage: `url(${MA})`,
                    borderRadius: 30
                  }}
                >
                  <div className="text">
                    <span>CoDoc Mobile Application</span>
                    <h3>
                      <Link to="../portfolio/codoc-mobile-app">Interior Design</Link>
                    </h3>
                    <Link
                     to="../portfolio/codoc-mobile-app"
                      className="icon d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-2 pl-md-5 py-5">
                <div className="row justify-content-start pb-3 pt-md-5">
                  <div className="col-md-12 heading-section ">
                    <h2 className="mb-4">CoDoc Mobile Application</h2>
                    <p>
                      At MCodez IT Solutions, we are dedicated to developing
                      innovative solutions that address the specific needs of
                      various industries. Our latest project is CoDoc, a
                      comprehensive healthcare assistance app designed to support
                      medical professionals and improve patient care
                    </p>
                  </div>
                </div>
                <div style={{ border: "2px solid black" }} />
              </div>
              {/* Section 3: Image > Content on Web, Content > Image on Mobile */}
              <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
                <div className="row justify-content-start pb-3 pt-md-5">
                  <div className="col-md-12 heading-section ">
                    <h2 className="mb-4">MySchool Admin Mobile Application</h2>
                    <p>
                      At MCodez IT Solutions, we specialize in creating tailored
                      solutions that enhance operational efficiency and user
                      experience. Our recent project involved the development of a
                      comprehensive Admin App designed specifically for
                      educational institutions.
                    </p>
                  </div>
                </div>
                <div style={{ border: "2px solid black" }} />
              </div>
              <div className="col-md-6 order-1 order-md-2 d-flex align-items-stretch mt-2 py-2">
                <div
                  className="project-wrap img d-flex align-items-end"
                  style={{
                   
                    backgroundImage: `url(${Admin})`,
                    borderRadius: 30
                  }}
                >
                  <div className="text">
                    <span>MySchool Admin Mobile Application</span>
                    <h3>
                      <Link to="/portfolio/myschool-admin-app">Interior Design</Link>
                    </h3>
                    <Link
                      to="/portfolio/myschool-admin-app"
                      className="icon d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* Section 4: Content > Image on both Web and Mobile */}
              <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
                <div
                  className="project-wrap img d-flex align-items-end"
                  style={{
                   
                    backgroundImage: `url(${staff})`,
                    borderRadius: 30
                  }}
                >
                  <div className="text">
                    <span>MySchool Staff Mobile Application</span>
                    <h3>
                      <Link to="/portfolio/myschool-staff-app">Interior Design</Link>
                    </h3>
                    <Link
                      to="/portfolio/myschool-staff-app"
                      className="icon d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-2 pl-md-5 py-5">
                <div className="row justify-content-start pb-3 pt-md-5">
                  <div className="col-md-12 heading-section ">
                    <h2 className="mb-4">MySchool Staff Mobile Application</h2>
                    <p>
                      At MCodez IT Solutions, we excel in creating intuitive and
                      efficient solutions tailored to the specific needs of our
                      clients. Our latest project is a comprehensive School
                      Management App developed to assist school staff in managing
                      attendance, assigning homework, and recording test and exam
                      results.
                    </p>
                  </div>
                </div>
                <div style={{ border: "2px solid black" }} />
              </div>
              {/* Section 5: Content > Image on both Web and Mobile */}
              <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
                <div className="row justify-content-start pb-3 pt-md-5">
                  <div className="col-md-12 heading-section ">
                    <h2 className="mb-4">MySchool Parents Mobile Application</h2>
                    <p>
                      MCodez IT Solutions is excited to introduce the "My School"
                      app, a comprehensive communication platform designed to
                      bridge the gap between parents and teachers. This mobile
                      application provides parents with real-time updates on their
                      child's academic activities, school events, and important
                      announcements.
                    </p>
                  </div>
                </div>
                <div style={{ border: "2px solid black" }} />
              </div>
              <div className="col-md-6 order-1 order-md-2 d-flex align-items-stretch mt-2 py-2">
                <div
                  className="project-wrap img d-flex align-items-end"
                  style={{
                    backgroundImage: `url(${MySchoolMa})`,
                    borderRadius: 30
                  }}
                >
                  <div className="text">
                    <span>MySchool Parents Mobile Application</span>
                    <h3>
                      <Link to="/portfolio/myschool-mobile-app">Interior Design</Link>
                    </h3>
                    <Link
                      to="/portfolio /myschool-mobile-app"
                      className="icon d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/*?php include("components/footer.php") ?*/}
    
  </>
  
}


export default MobileApplicationDevelopment;