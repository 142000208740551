import web2 from  "../images/web2.webp"
import bg_mobile from "../images/bg_mobile.webp"
import bg_web from "../images/bg_web.webp"
import smm2 from "../images/smm2.webp"
import uxui from "../images/ui-ux.webp"
import { Link } from "react-router-dom"

const Services=()=>{
return <><>
{/*?php 
include("./components/header.php");
?*/}

<section
  className="hero-wrap hero-wrap-2"
  style={{ backgroundImage: `url(${web2})` }}
>
  <div className="overlay" />
  <div className="container">
    <div className="row no-gutters slider-text align-items-end">
      <div className="col-md-9  pb-5">
        <p className="breadcrumbs">
          <span className="mr-2">
            <Link to="/">
              Home <i className="fa fa-chevron-right" />
            </Link>
          </span>{" "}
          <span>
            Services <i className="fa fa-chevron-right" />
          </span>
        </p>
        <h1 className="mb-0 bread">Services</h1>
      </div>
    </div>
  </div>
</section>
<section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
  <div className="container">
    <div className="row d-flex">
      <div className="col-md-12 about-intro">
        <div className="row d-flex">
          <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
            <div className="row justify-content-start pb-3 pt-md-5">
              <div className="col-md-12 heading-section ">
                <h2 className="mb-4 heading">
                  Mobile App Development Services
                </h2>
                <p>
                  Our mobile app development services cover a wide spectrum,
                  including Android app development, iOS app development, and
                  cross-platform app development. We leverage the latest
                  technologies and industry best practices to create
                  intuitive, engaging, and high-performing mobile applications
                  that resonate with your target audience.
                </p>
              </div>
            </div>
            <div style={{ border: "2px solid black" }} />
          </div>
          <div className="col-md-6 order-1 d-flex align-items-stretch mt-5 py-5">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{
                backgroundImage: `url(${bg_mobile})`,
                borderRadius: 30
              }}
            >
              <div className="text">
                <span>Mobile App Development</span>
                <h3>
                  <a href="./mobile-app-development.php">Interior Design</a>
                </h3>
                <Link
                  to="../service/mobile-app-development"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          {/* Section 2: Content > Image on both Web and Mobile */}
          <div className="col-md-6 order-1 d-flex align-items-stretch mt-4 py-4">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{
                backgroundImage: `url(${web2})`,
                borderRadius: 30
              }}
            >
              <div className="text">
                <span>Website Development Services</span>
                <h3>
                  <Link to="/services/web-development">Interior Design</Link>
                </h3>
                <Link
                  to="/services/web-development"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 order-2 pl-md-5 ">
            <div className="row justify-content-start pb-3 pt-md-5">
              <div className="col-md-12 heading-section ">
                <h2 className="mb-4">Website Development Services</h2>
                <p>
                  As a leading web development company in India, we excel in
                  delivering website development services that make your
                  online presence stand out. Our team of experienced
                  developers and designers craft visually appealing,
                  user-friendly, and responsive websites that drive traffic,
                  conversions, and business growth.
                </p>
              </div>
            </div>
            <div style={{ border: "2px solid black" }} />
          </div>
        </div>
      </div>
      <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
        <div className="row justify-content-start pb-3 pt-md-5">
          <div className="col-md-12 heading-section ">
            <h2 className="mb-4 heading">Software Development Services</h2>
            <p>
              At MCodez IT Solutions, we offer end-to-end custom software
              development services to streamline your business processes and
              enhance productivity. Our software development agency in India
              specializes in developing scalable, secure, and customized
              software solutions that meet your specific requirements and
              business objectives.
            </p>
          </div>
        </div>
        <div style={{ border: "2px solid black" }} />
      </div>
      <div className="col-md-6 order-1 d-flex align-items-stretch mt-5 py-5">
        <div
          className="project-wrap img d-flex align-items-end"
          style={{
            backgroundImage: `url(${bg_web})`,
            borderRadius: 30
          }}
        >
          <div className="text">
            <span>Software Development Services</span>
            <h3>
              <Link to="../services/custom-software-development">Interior Design</Link>
            </h3>
            <Link
              to="../services/custom-software-development"
              className="icon d-flex align-items-center justify-content-center"
            >
              <span className="fa fa-chevron-right" />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-6 order-1 d-flex align-items-stretch mt-4 py-4">
        <div
          className="project-wrap img d-flex align-items-end"
          style={{
            backgroundImage: `url(${smm2})`,
            borderRadius: 30
          }}
        >
          <div className="text">
            <span>Social Media Optimization Services</span>
            <h3>
              <Link to="../services/social-media-management">Interior Design</Link>
            </h3>
            <Link
              to="../services/social-media-management"
              className="icon d-flex align-items-center justify-content-center"
            >
              <span className="fa fa-chevron-right" />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-6 order-2 pl-md-5 ">
        <div className="row justify-content-start pb-3 pt-md-5">
          <div className="col-md-12 heading-section ">
            <h2 className="mb-4">Social Media Optimization Services</h2>
            <p>
              At MCodez it Solutions, we boost your online presence and drive
              results with our comprehensive Social Media Optimization
              solutions. From Posting and advertising to social media
              marketing and content creation, we have the expertise to help
              you achieve your business goals in the digital realm.
            </p>
          </div>
        </div>
        <div style={{ border: "2px solid black" }} />
      </div>
      <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
        <div className="row justify-content-start pb-3 pt-md-5">
          <div className="col-md-12 heading-section ">
            <h2 className="mb-4 heading">UI/UX Designing Services</h2>
            <p>
              We create engaging and intuitive digital experiences with our
              UI/UX design services. From user research and wireframing to
              prototyping and usability testing, we ensure that your digital
              products deliver exceptional user experiences that drive
              engagement and satisfaction.
            </p>
          </div>
        </div>
        <div style={{ border: "2px solid black" }} />
      </div>
      <div className="col-md-6 order-1 d-flex align-items-stretch mt-4 py-4">
        <div
          className="project-wrap img d-flex align-items-end"
          style={{
            backgroundImage: `url(${uxui})`,
            borderRadius: 30
          }}
        >
          <div className="text">
            <span>UI/UX Designing Services</span>
            <h3>
              <Link to="../services/uiux-design-dev">Interior Design</Link>
            </h3>
            <Link
              to="../services/uiux-design-dev"
              className="icon d-flex align-items-center justify-content-center"
            >
              <span className="fa fa-chevron-right" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/*?php 
include("./components/footer.php");
?*/}
</>
</>
}

export default Services;