import bg_mobile  from "../images/bg_mobile.webp"
import { Link } from "react-router-dom";
import YCC_WEB from "../projects/YCC_Mobileapp.webp"


const YccMobileApp=()=>{
         return <>
         {/*?php
         include("./components/header.php");
       ?*/}
         <section
           className="hero-wrap hero-wrap-2"
           style={{ backgroundImage: `url(${bg_mobile})` }}
         >
           <div className="overlay" />
           <div className="container">
             <div className="row no-gutters slider-text align-items-end">
               <div className="col-md-9  pb-5">
                 <p className="breadcrumbs">
                   <span className="mr-2">
                     <Link to="/">
                       Portfolio <i className="fa fa-chevron-right" />
                     </Link>
                   </span>{" "}
                   <span>
                     YCC Mobile App <i className="fa fa-chevron-right" />
                   </span>
                 </p>
                 <h1 className="mb-0 bread" />
                 <p className="breadcrumbs">
                   <span className="mr-2">
                     MCodez IT Solutions is proud to present the "Yogyata" Student
                     Science Dashboard, a comprehensive mobile application designed to
                     enhance the academic experience of students. Developed for YCC
                     School, this app serves as a dynamic platform for students to
                     monitor their academic progress, attendance, and receive important
                     notifications.
                     <i className="fa fa-chevron-right" />
                   </span>
                 </p>
               </div>
             </div>
           </div>
         </section>
         <div className="container py-4 mt-2">
           <div className="row justify-content-center mb-5">
             <div className="col-md-7 heading-section text-center">
               <span className="subheading">Mobile Application</span>
               <h2>Yogyata Coaching Centre Mobile App</h2>
             </div>
           </div>
         </div>
         <div style={{ marginTop: "-10%" }} />
         <section className="ftco-section ftco-no-pt ftco-no-pb">
           <div className="container">
             <div className="row justify-content-center">
               <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
                 <p style={{ textAlign: "center" }}>
                   <img
                     src={YCC_WEB}
                     alt=""
                     className="img-fluid"
                   />
                 </p>
                 <span className="portfolio-category">Mobile Application</span>
                 <h2 className="mb-3 heading-title">
                   Yogyata Coaching Centre Mobile App
                 </h2>
                 <ul
                   className="pricing-text mb-2 list-unstyled"
                   style={{ color: "black" }}
                 >
                   <li>
                     <span className="fa fa-check-circle mr-2" />
                     <b>Intuitive Dashboard:</b> The Yogyata app features a
                     user-friendly dashboard that provides students with a quick
                     overview of their attendance and academic notifications.
                   </li>
                   <li>
                     <span className="fa fa-check-circle mr-2" />
                     <b>Attendance Tracking: </b>Students can easily track their total
                     and daily attendance status with clear indicators for Present (P),
                     Holiday (H), and Absent (A). This helps them stay informed about
                     their attendance record.
                   </li>
                   <li>
                     <span className="fa fa-check-circle mr-2" />
                     <b>Notifications:</b> The app keeps students updated with
                     real-time alerts for holidays, tests, and other important events.
                     Notifications are prominently displayed to ensure students never
                     miss any critical updates.
                   </li>
                   <li>
                     <span className="fa fa-check-circle mr-2" />
                     <b>Progress Tracking: </b> Yogyata allows students to monitor
                     their academic progress through a visual progress tracker. This
                     feature helps students stay on top of their studies and prepare
                     effectively for upcoming tests.
                   </li>
                 </ul>
                 <div className="tag-widget post-tag-container mb-5 mt-5">
                   <div className="tagcloud">
                   <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                     <Link href="#" className="tag-cloud-link">
                       Digital Marketing
                     </Link>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
         <section className="ftco-section testimony-section ftco-no-pt bg-light">
           <div className="overlay" />
           <div className="container">
             <div className="row pb-4">
               <div className="col-md-7 heading-section ">
                 <span className="subheading" style={{ marginTop: "10%" }}>
                   Testimonial
                 </span>
                 <h2 className="mb-4">
                   People Say <br />
                   About This Work
                 </h2>
               </div>
             </div>
           </div>
           <div className="container-fluid px-lg-5">
             <div className="row ">
               <div className="col-md-12">
                 <div className="card" style={{ borderRadius: 30 }}>
                   <div className="item">
                     <d className="testimony-wrap py-4">
                       <div className="text">
                         <p className="mb-4">
                           <i>
                             "The Yogyata app has been a game-changer for our students.
                             It has made it easier for them to stay updated with their
                             academic responsibilities and manage their time
                             effectively. MCodez IT Solutions has delivered an
                             exceptional product that truly meets our needs."
                           </i>
                         </p>
                         <div className="d-flex align-items-center">
                           <div
                             className="user-img"
                             style={{ backgroundImage: "url(images/person_1.jpg)" }}
                           />
                           <div className="pl-3">
                             <p className="star">
                               <span className="fa fa-star" />
                               <span className="fa fa-star" />
                               <span className="fa fa-star" />
                               <span className="fa fa-star" />
                               <span className="fa fa-star-o" />
                             </p>
                             <p className="name">Mr. Jitender</p>
                             <span className="position">Founder of YCC</span>
                           </div>
                         </div>
                       </div>
                     </d>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
         <section className="ftco-section ftco-no-pb">
           <div className="overlay" />
           <div className="container">
             <div className="row">
               <div className="col-md-7 heading-section ">
                 <span className="subheading">Tech Stack</span>
                 <h2>Technologies Used Are</h2>
               </div>
             </div>
           </div>
           <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
             <span className="flaticon-php" />
             <span className="flaticon-mysql" />
             <span className="flaticon-laravel" />
             <span className="flaticon-flutter" />
             <span className="flaticon-dart" />
             <span className="flaticon-firebase" />
           </div>
           {/*?php include("./components/contact.php") ?*/}
         </section>
         {/*?php
         include("./components/footer.php");
       ?*/}
       </>
       
}

export default YccMobileApp;