
import { Link } from "react-router-dom";
import web2 from "../images/web2.webp";
import Ecom from "../projects/MyCart-Ecomm.webp";



const MyCartProEcom=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${web2})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/">
                  Portfolio <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                YCC Web App <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">MyCart Pro Ecommerce</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                MCodez IT Solutions is proud to present My Cart Pro, a
                comprehensive e-commerce platform designed to facilitate seamless
                online shopping experiences. This platform caters to both buyers
                and sellers, offering a wide range of products and robust features
                to enhance user engagement.
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Website Application</span>
          <h2>MyCart Pro Ecommerce</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img
                src={Ecom}
                alt=""
                className="img-fluid"
              />
            </p>
            <span className="portfolio-category">Web Application</span>
            <h2 className="mb-3 heading-title">MyCart Pro Ecommerce</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>User-Friendly Interface: </b> My Cart Pro features an intuitive
                and visually appealing interface that simplifies the shopping
                process for users. Easy navigation ensures a smooth browsing
                experience.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Diverse Product Categories: </b>The platform offers a vast
                selection of products across various categories, including
                fashion, cosmetics, electronics, and more, ensuring something for
                every shopper.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Seller Zone:</b>My Cart Pro provides a dedicated area for
                sellers to manage their products, track orders, and engage with
                customers, fostering a vibrant marketplace community.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Real-Time Notifications: </b>Users receive real-time updates on
                flash sales, new product arrivals, and other promotions, helping
                them stay informed and take advantage of the best deals.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Secure Transactions: </b> The platform ensures secure payment
                gateways, safeguarding users' financial information and providing
                a trustworthy shopping environment.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
              <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      <i>
                        "The development of My Cart Pro by MCodez IT Solutions has
                        significantly enhanced our e-commerce capabilities. The
                        platform is robust, user-friendly, and has received
                        positive feedback from both buyers and sellers. MCodez has
                        delivered an outstanding product that meets all our
                        requirements."
                      </i>
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">– My Cart Pro Management</p>
                        <span className="position" />
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-php" />
        <span className="flaticon-mysql" />
        <span className="flaticon-laravel" />
        <span className="flaticon-bootstrap" />
        <span className="flaticon-jquery" />
        <span className="flaticon-javascript" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}


export default MyCartProEcom;