import { Link } from "react-router-dom";
import web2 from "../images/web2.webp"
import Y from "../projects/YCC-SD.webp"

const YccWeb=()=>{
  return <>
  {/*?php
  include("./components/header.php");
?*/}
  <section
    className="hero-wrap hero-wrap-2"
    style={{ backgroundImage: `url(${web2})` }}
  >
    <div className="overlay" />
    <div className="container">
      <div className="row no-gutters slider-text align-items-end">
        <div className="col-md-9  pb-5">
          <p className="breadcrumbs">
            <span className="mr-2">
              <Link to="/">
                Portfolio <i className="fa fa-chevron-right" />
              </Link>
            </span>{" "}
            <span>
              YCC Web App <i className="fa fa-chevron-right" />
            </span>
          </p>
          <h1 className="mb-0 bread">YCC Web App</h1>
          <p className="breadcrumbs">
            <span className="mr-2">
              At MCodez IT Solutions, we specialize in creating secure and
              efficient web applications tailored to specific business needs.
              One of our standout projects is the YCC Management Portal,
              designed to streamline the operations and management of Youth
              Cultural Center activities.
              <i className="fa fa-chevron-right" />
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
  <div className="container py-4 mt-2">
    <div className="row justify-content-center mb-5">
      <div className="col-md-7 heading-section text-center">
        <span className="subheading">Website Application</span>
        <h2>Youth Cultural Center (YCC)</h2>
      </div>
    </div>
  </div>
  <div style={{ marginTop: "-10%" }} />
  <section className="ftco-section ftco-no-pt ftco-no-pb">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
          <p style={{ textAlign: "center" }}>
            <img src={Y} alt="" className="img-fluid" />
          </p>
          <span className="portfolio-category">Mobile Application</span>
          <h2 className="mb-3 heading-title">Youth Cultural Center (YCC)</h2>
          <ul
            className="pricing-text mb-2 list-unstyled"
            style={{ color: "black" }}
          >
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Secure Login: </b> Ensures only authorized access to the portal
              with username and password protection
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>User Management: </b>Simplifies the administration of user
              accounts, including registration, profile updates, and access
              permissions.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Event Management:</b>Enables efficient handling of event
              registrations, scheduling, and participant tracking.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Resource Management: </b> : Sends automated notifications to
              users regarding event updates and important announcements
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Reporting: </b> Generates detailed reports on various
              activities and user engagement for better decisionmaking
            </li>
          </ul>
          <div className="tag-widget post-tag-container mb-5 mt-5">
            <div className="tagcloud">
            <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
              <Link to="#" className="tag-cloud-link">
                Digital Marketing
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section testimony-section ftco-no-pt bg-light">
    <div className="overlay" />
    <div className="container">
      <div className="row pb-4">
        <div className="col-md-7 heading-section ">
          <span className="subheading" style={{ marginTop: "10%" }}>
            Testimonial
          </span>
          <h2 className="mb-4">
            People Say <br />
            About This Work
          </h2>
        </div>
      </div>
    </div>
    <div className="container-fluid px-lg-5">
      <div className="row ">
        <div className="col-md-12">
          <div className="card" style={{ borderRadius: 30 }}>
            <div className="item">
              <d className="testimony-wrap py-4">
                <div className="text">
                  <p className="mb-4">
                    <i>
                      "The YCC Management Portal developed by MCodez IT
                      Solutions has significantly improved our administrative
                      processes. The user-friendly interface and comprehensive
                      management tools have made our operations more efficient.
                      We highly recommend their services for any organization
                      looking to enhance their digital infrastructure."
                    </i>
                  </p>
                  <div className="d-flex align-items-center">
                    <div
                      className="user-img"
                      style={{ backgroundImage: "url(images/person_1.jpg)" }}
                    />
                    <div className="pl-3">
                      <p className="star">
                        <span className="fa fa-star" />
                        <span className="fa fa-star" />
                        <span className="fa fa-star" />
                        <span className="fa fa-star" />
                        <span className="fa fa-star-o" />
                      </p>
                      <p className="name">Mr. Jitender</p>
                      <span className="position">Founder of YCC</span>
                    </div>
                  </div>
                </div>
              </d>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-7 heading-section ">
          <span className="subheading">Tech Stack</span>
          <h2>Technologies Used Are</h2>
        </div>
      </div>
    </div>
    <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
      <span className="flaticon-php" />
      <span className="flaticon-mysql" />
      <span className="flaticon-laravel" />
      <span className="flaticon-bootstrap" />
      <span className="flaticon-jquery" />
      <span className="flaticon-javascript" />
    </div>
    {/*?php include("./components/contact.php") ?*/}
  </section>
  {/*?php
  include("./components/footer.php");
?*/}
</>

}

export default YccWeb;