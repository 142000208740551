import Contact from "./Contact";
import ServiceNav from "./ServiceNav";
import web2 from "../images/web2.webp";
import web_service from "../images/web_service_1.webp"
import { Link } from "react-router-dom";
import YCC from "../projects/YCC-SD.webp";
import Propoint from "../projects/Propoint-SD.webp";
import PropointSol from "../projects/ProPoint-Web.webp";


const WebSiteDevelopment=()=>{
  return <>
  {/*?php
  include("./components/header.php");
?*/}
  <section
    className="hero-wrap hero-wrap-2"
    style={{ backgroundImage: `url(${web2})` }}
  >
    <div className="overlay" />
    <div className="container">
      <div className="row no-gutters slider-text align-items-end">
        <div className="col-md-9  pb-5">
          <p className="breadcrumbs">
            <span className="mr-2">
              <Link to="/">
                Home <i className="fa fa-chevron-right" />
              </Link>
              &nbsp;
              <a href="services.php">
                Services <i className="fa fa-chevron-right" />
              </a>
            </span>{" "}
            <span>Website Development </span>
          </p>
          <h1 className="mb-0 bread">Website Development</h1>
          {/* <p class="breadcrumbs"><span class="mr-2">Our mobile app development services cover a wide spectrum, including Android app development, iOS app development, and cross-platform app development. We leverage the latest technologies and industry best practices to create intuitive, engaging, and high-performing mobile applications that resonate with your target audience.</span></p> */}
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-7 heading-section ">
          <span className="subheading">Website Development</span>
          <h2>EMPOWERING INNOVATION</h2>
          <p>
            Your website serves as the digital storefront for your business,
            making a lasting impression on visitors and potential customers. At
            MCodez IT Solutions, we specialize in designing and developing
            visually stunning, user-friendly, and responsive websites that
            captivate audiences and drive conversions. Our website development
            services cover a wide range of solutions, including corporate
            websites, e-commerce platforms, content management systems (CMS),
            and custom web applications. We start by understanding your brand
            identity, target audience, and business objectives. Our team of
            skilled designers and developers then collaborates to create a
            unique and engaging online presence that reflects your brand's
            personality and values. We focus on optimizing user experience, site
            performance, and search engine visibility to ensure that your
            website not only looks great but also delivers measurable results.
          </p>
        </div>
        <div className="col-md-5 mt-md-5 py-5">
          <img
            src={web_service}
            width="100%"
            height="auto"
            alt="Animated GIF"
          />
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-12 heading-section ">
          <span className="subheading">Website Development</span>
          <h2>Our Website Development Process</h2>
          <ul
            className="pricing-text mb-2 list-unstyled"
            style={{ color: "black" }}
          >
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Comprehensive Solutions: </b>Our website development services
              cover a wide range of solutions, including corporate websites,
              e-commerce platforms, content management systems (CMS), and custom
              web applications. We tailor each project to meet the specific
              needs and goals of our clients. This versatility allows us to
              create effective online platforms for a variety of industries and
              purposes
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Understanding Your Brand: </b>We start by understanding your
              brand identity, target audience, and business objectives. This
              initial phase is crucial as it guides the entire development
              process. By aligning our efforts with your brand’s personality and
              values, we ensure the final product is authentic and effective.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Collaborative Design and Development:</b>Our team of skilled
              designers and developers collaborates to create a unique and
              engaging online presence. This collaboration ensures that every
              aspect of the website, from aesthetics to functionality, is
              meticulously crafted. Our goal is to deliver a cohesive and
              compelling website that stands out in the digital landscape.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Optimizing User Experience: </b>We focus on optimizing user
              experience, site performance, and search engine visibility. A
              great user experience leads to higher engagement and conversion
              rates, while a well-performing site ensures reliability.
              Additionally, our SEO strategies help improve your website’s
              visibility, driving more traffic and potential customers.
            </li>
            <li>
              <span className="fa fa-check-circle mr-2" />
              <b>Delivering Measurable Results: </b>Our websites are not just
              about looks; they are designed to deliver measurable results. We
              implement analytics and tracking tools to monitor site performance
              and user behavior. This data-driven approach allows us to
              continually refine and improve the website to meet your business
              goals.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/* PORTFOLIO START HERE  */}
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row justify-content-between pb-5">
        <div className="col-md-6 col-lg-6 heading-section heading-section-white ">
          <span className="subheading">Recent Portfolio</span>
          <h2 style={{ color: "black" }}>Our Recent Projects</h2>
        </div>
        <div className="col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
          <Link to="/portfolio/web-development" className="btn-custom">
            View All Projects <span className="fa fa-chevron-right" />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 ">
          <div
            className="project-wrap img d-flex align-items-end"
            style={{ backgroundImage: `url(${YCC})` }}
          >
            <div className="text">
              <span>Yogyata Coaching Centre</span>
              <h3>
                <a to="../portfolio/ycc-web-app">Interior Design</a>
              </h3>
              <Link
                to="../portfolio/ycc-web-app"
                className="icon d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div
            className="project-wrap img d-flex align-items-end"
            style={{ backgroundImage: `url(${Propoint})` }}
          >
            <div className="text">
              <span>ProPoint Connect</span>
              <h3>
                <Link to="../portfolio/propoint-web-app">Interior Design</Link>
              </h3>
              <Link
                to="../portfolio/propoint-web-app"
                className="icon d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4 ">
          <div
            className="project-wrap img d-flex align-items-end"
            style={{ backgroundImage: `url(${PropointSol})` }}
          >
            <div className="text">
              <span>ProPoint Solutions</span>
              <h3>
                <Link href="../portfolio/propoint-website">Interior Design</Link>
              </h3>
              <Link
                href="../portfolio/propoint-website"
                className="icon d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* PORTFOLIO SECTION END HERE  */}
  <section className="ftco-section ftco-no-pb">
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-7 heading-section ">
          <span className="subheading">Tech Stack</span>
          <h2>Technologies Used Are</h2>
        </div>
      </div>
    </div>
    <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
      <span className="flaticon-node" />
      <span className="flaticon-mysql" />
      <span className="flaticon-mongodb" />
      <span className="flaticon-react" />
      <span className="flaticon-php" />
      <span className="flaticon-laravel" />
      <span className="flaticon-javascript" />
      <span className="flaticon-jquery" />
    </div>
    {/*?php include("./components/servicesNav.php") ?*/}
    <ServiceNav/>
    {/*?php include("./components/contact.php") ?*/}
    <Contact/>
  </section>
  {/*?php
  include("./components/footer.php");
?*/}
</>

}

export default WebSiteDevelopment;