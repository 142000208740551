import Service from "./Service";
import bg_mobile_3 from "../images/bg_mobile_3.webp"
import MySchoolMA from "../projects/MySchool-MA.webp"
import AdminMA from "../projects/Admin-MA.webp"
import StaffMA from "../projects/Staff-MA.webp"
import DrAsohak from "../projects/Dr.Ashok-Ecom.webp"
import YCCSD from "../projects/YCC-SD.webp";
import LegalstixCRM from "../projects/Legalstix-CRM-SD.webp";
import  YCCMobile from "../projects/YCC_Mobileapp.webp"
import MySchool from "../projects/MySchoolERP-SD.webp"
import LevNext from "../projects/LevNext-CRM-SD.webp" 
import MyCart from "../projects/MyCart-Ecomm.webp"
import BrightWorld from "../projects/BrightWorld-MA.webp"
import ProPoint from "../projects/ProPoint-Web.webp";
import ProPointSD from "../projects/Propoint-SD.webp"
import CodocMA from  "../projects/Codoc-MA.webp"
import  VLASD from "../projects/VLA-SD.webp"
import { Link } from "react-router-dom";

const Portfolio=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${bg_mobile_3})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/">
                  Home <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                Portfolio <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">Portfolio</h1>
          </div>
        </div>
      </div>
    </section>
    {/* SERVICES SECTION START */}
    {/*?php include("./components/services.php") ?*/}
<Service/>
    {/* SERVICES SECTION START */}
    <section className="ftco-section">
      <h1 align="center">Our Recent Works</h1>
      <div className="container py-2">
        <div className="row">
          <div className="col-md-4">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${ MySchoolMA })` }}
            >
              <div className="text">
                <span>My School Mobile Application</span>
                <h3>
                  <Link to="../portfolio/myschool-mobile-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/myschool-mobile-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">MySchool App</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${AdminMA})` }}
            >
              <div className="text">
                <span>My School Admin App</span>
                <h3>
                  <Link to="../portfolio/myschool-admin-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/myschool-admin-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">MySchool Admin App</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${StaffMA})` }}
            >
              <div className="text">
                <span>My School Staff App</span>
                <h3>
                  <Link to="../portfolio/myschool-staff-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/myschool-staff-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">MySchool Staff App</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${DrAsohak})` }}
            >
              <div className="text">
                <span>Dr. Ashok Ecom Portal</span>
                <h3>
                  <Link to="../portfolio/dr-ashok-ecommerce">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/dr-ashok-ecommerce"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">Dr. Ashok Ecom Portal</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${YCCSD})`}}
            >
              <div className="text">
                <span>YCC Web Application</span>
                <h3>
                  <Link to="../portfolio/ycc-web-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/ycc-web-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">YCC Web App</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${YCCMobile})` }}
            >
              <div className="text">
                <span>YCC Mobile Application</span>
                <h3>
                  <Link to="../portfolio/ycc-mobile-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/ycc-mobile-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">YCC Mobile App</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${MySchool})` }}
            >
              <div className="text">
                <span>MySchool ERP</span>
                <h3>
                  <Link to="../portfolio/myschool-erp">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/myschool-erp"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">MySchool ERP</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${LegalstixCRM})` }}
            >
              <div className="text">
                <span>Leads Management System</span>
                <h3>
                  <Link to="../portfolio/leads-management-system">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/leads-management-system"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">Leads Management Web App</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${LevNext})` }}
            >
              <div className="text">
                <span>Project Tracker</span>
                <h3>
                  <Link to="../portfolio/project-tracker">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/project-tracker"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">Project Tracker</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${MyCart})` }}
            >
              <div className="text">
                <span>MyCart Pro</span>
                <h3>
                  <Link to="../portfolio/project-tracker">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/project-tracker"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">MyCart Pro Ecom</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${BrightWorld})` }}
            >
              <div className="text">
                <span>BrightWorld Mobile Application</span>
                <h3>
                  <Link to="../portfolio/bright-world-mobile-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/bright-world-mobile-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">BrightWorld Mobile Application</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${ProPoint})` }}
            >
              <div className="text">
                <span>Propoint Website</span>
                <h3>
                  <Link to="../portfolio/propoint-website">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/propoint-website"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">ProPoint Website</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${ProPointSD})` }}
            >
              <div className="text">
                <span>Propoint Web App</span>
                <h3>
                  <Link to="../portfolio/propoint-web-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/propoint-web-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">ProPoint Job Portal</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${CodocMA})` }}
            >
              <div className="text">
                <span>CoDoc Mobile App</span>
                <h3>
                  <Link to="../portfolio/codoc-mobile-app">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/codoc-mobile-app"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">CoDoc Mobile App</h5>
          </div>
          <div className="col-md-4 ">
            <div
              className="project-wrap img d-flex align-items-end"
              style={{ backgroundImage: `url(${VLASD})` }}
            >
              <div className="text">
                <span>VLA Web App</span>
                <h3>
                  <Link to="../portfolio/vla">Interior Design</Link>
                </h3>
                <Link
                  to="../portfolio/vla"
                  className="icon d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-chevron-right" />
                </Link>
              </div>
            </div>
            <h5 align="center">VLA Web App</h5>
          </div>
        </div>
        {/* <div class="row mt-5">
  <div class="col text-center">
  <div class="block-27">
  <ul>
  <li><a href="#">&lt;</a></li>
  <li class="active"><span>1</span></li>
  <li><a href="#">2</a></li>
  <li><a href="#">3</a></li>
  <li><a href="#">4</a></li>
  <li><a href="#">5</a></li>
  <li><a href="#">&gt;</a></li>
  </ul>
  </div>
  </div>
  </div> */}
      </div>
    </section>
    <div style={{ marginBottom: "-10%" }} />
    {/*?php 
    include("./components/footer.php");
  ?*/}
  </>
  
}


export default Portfolio;