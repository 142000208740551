import { Link } from "react-router-dom";
import bg_mobile from "../images/bg_mobile.webp"
import CoDoc from "../projects/Codoc-MA.webp" 

const Codoc=()=>{
    return <>
    {/* Hello world */}
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${bg_mobile})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <Link to="/portfolio">
                  Portfolio <i className="fa fa-chevron-right" />
                </Link>
              </span>{" "}
              <span>
                CoDoc Mobile App <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread" />
            <p className="breadcrumbs">
              <span className="mr-2">
                At MCodez IT Solutions, we are dedicated to developing innovative
                solutions that address the specific needs of various industries.
                Our latest project is CoDoc, a comprehensive healthcare assistance
                app designed to support medical professionals and improve patient
                care.
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Mobile Application</span>
          <h2>CoDoc Mobile App</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img src={CoDoc} alt="" className="img-fluid" />
            </p>
            <span className="portfolio-category">Mobile Application</span>
            <h2 className="mb-3 heading-title">CoDoc Mobile App</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Welcome Screen:</b> A warm and professional welcome screen that
                sets the tone for the app, reflecting its medical focus
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>FAQ Section: </b>: A dedicated FAQ section that provides quick
                answers to common questions, ensuring that users can easily find
                the information they need.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Resourceful Content:</b> : Organized sections with headings and
                descriptions that guide users to relevant information and
                resources, supporting their medical practice.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>User-Friendly Interface: </b> : Designed with a clean and
                intuitive interface to ensure ease of use for medical
                professionals of all tech-savviness levels.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Responsive Design:</b> Optimized for both Android and iOS
                devices, providing a seamless experience across different
                platforms.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
                <Link to="/services/web-development" className="tag-cloud-link">
                  Web Development
                </Link>
                <Link to="/service/mobile-app-development" className="tag-cloud-link">
                  Mobile App Development
                </Link>
                <Link to="/services/custom-software-development"className="tag-cloud-link">
                  Custom Software Development
                </Link>
                <Link to="/services/social-media-management" className="tag-cloud-link">
                  Social Media Management
                </Link>
                <Link to="/services/ui-ux-designing"className="tag-cloud-link">
                  UI-UX Designing
                </Link>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      <i>
                        "The CoDoc app developed by MCodez IT Solutions has been a
                        game-changer for our medical staff. The intuitive
                        interface and easy access to important resources have
                        significantly improved our daily operations. The team's
                        dedication to understanding our needs and their commitment
                        to delivering a high-quality product were exceptional. We
                        highly recommend MCodez IT Solutions for their expertise
                        and professionalism."
                      </i>
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">--Bright World Administrator</p>
                        <span className="position">
                          Bright World Management Team
                        </span>
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-php" />
        <span className="flaticon-mysql" />
        <span className="flaticon-laravel" />
        <span className="flaticon-flutter" />
        <span className="flaticon-dart" />
        <span className="flaticon-firebase" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}


export default Codoc;