import Contact from "./Contact";
import bg_mobile from "../images/bg_mobile.webp"
import bg_myschool_app from "../projects/MySchool-MA.webp";



const MySchoolApp=()=>{
    return <>
    {/*?php
    include("./components/header.php");
  ?*/}
    <section
      className="hero-wrap hero-wrap-2"
      style={{ backgroundImage: `url(${bg_mobile})` }}
    >
      <div className="overlay" />
      <div className="container">
        <div className="row no-gutters slider-text align-items-end">
          <div className="col-md-9  pb-5">
            <p className="breadcrumbs">
              <span className="mr-2">
                <a href="/">
                  Portfolio <i className="fa fa-chevron-right" />
                </a>
              </span>{" "}
              <span>
                MySchool App <i className="fa fa-chevron-right" />
              </span>
            </p>
            <h1 className="mb-0 bread">MySchool App</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                MCodez IT Solutions is excited to introduce the "My School" app, a
                comprehensive communication platform designed to bridge the gap
                between parents and teachers. This mobile applic{" "}
                <i className="fa fa-chevron-right" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <div className="container py-4 mt-2">
      <div className="row justify-content-center mb-5">
        <div className="col-md-7 heading-section text-center">
          <span className="subheading">Mobile Application</span>
          <h2>MySchool Mobile App</h2>
        </div>
      </div>
    </div>
    <div style={{ marginTop: "-10%" }} />
    <section className="ftco-section ftco-no-pt ftco-no-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ftco-animate portfolio-single py-md-5 mt-md-5">
            <p style={{ textAlign: "center" }}>
              <img src={bg_myschool_app} alt="" className="img-fluid" />
            </p>
            <span className="portfolio-category">Mobile Application</span>
            <h2 className="mb-3 heading-title">MySchool &amp; App</h2>
            <ul
              className="pricing-text mb-2 list-unstyled"
              style={{ color: "black" }}
            >
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Secure Parent Login:</b> The My School app ensures a secure and
                personalized experience for parents with a dedicated login portal.
                Parents can easily access their child's information using their
                registered phone number and a secure password.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Live Classes: </b>Parents can stay informed about their child's
                live classes and join sessions directly from the app. This feature
                supports real-time engagement and monitoring of the child's
                learning activities.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Important Announcements:</b> The app provides a dedicated
                section for important messages from the school. Parents receive
                notifications about fee dues, attendance issues, and other
                critical information, ensuring they are always in the loop
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>Upcoming Events: </b> The My School app keeps parents updated
                with a calendar of upcoming school events. From cultural festivals
                to important academic dates, parents can view and prepare for
                upcoming activities.
              </li>
              <li>
                <span className="fa fa-check-circle mr-2" />
                <b>User-friendly Interface: </b>The app features a clean and
                intuitive interface, making it easy for parents to navigate and
                find the information they need quickly and efficiently.
              </li>
            </ul>
            <div className="tag-widget post-tag-container mb-5 mt-5">
              <div className="tagcloud">
                <a href="#" className="tag-cloud-link">
                  Web Development
                </a>
                <a href="#" className="tag-cloud-link">
                  Mobile App Development
                </a>
                <a href="#" className="tag-cloud-link">
                  Custom Software Development
                </a>
                <a href="#" className="tag-cloud-link">
                  Social Media Management
                </a>
                <a href="#" className="tag-cloud-link">
                  UI-UX Designing
                </a>
                <a href="#" className="tag-cloud-link">
                  Digital Marketing
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section testimony-section ftco-no-pt bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row pb-4">
          <div className="col-md-7 heading-section ">
            <span className="subheading" style={{ marginTop: "10%" }}>
              Testimonial
            </span>
            <h2 className="mb-4">
              People Say <br />
              About This Work
            </h2>
          </div>
        </div>
      </div>
      <div className="container-fluid px-lg-5">
        <div className="row ">
          <div className="col-md-12">
            <div className="card" style={{ borderRadius: 30 }}>
              <div className="item">
                <d className="testimony-wrap py-4">
                  <div className="text">
                    <p className="mb-4">
                      The My School app has been a fantastic tool for improving
                      communication with our students' parents. It has made it
                      easier for us to keep parents informed and engaged in their
                      child's education. MCodez IT Solutions has delivered an
                      outstanding app that meets all our requirements.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ backgroundImage: "url(images/person_1.jpg)" }}
                      />
                      <div className="pl-3">
                        <p className="star">
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star" />
                          <span className="fa fa-star-o" />
                        </p>
                        <p className="name">My School Administration</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </d>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="ftco-section ftco-no-pb">
      <div className="overlay" />
      <div className="container">
        <div className="row">
          <div className="col-md-7 heading-section ">
            <span className="subheading">Tech Stack</span>
            <h2>Technologies Used Are</h2>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-2 py-4" style={{ textAlign: "center" }}>
        <span className="flaticon-php" />
        <span className="flaticon-mysql" />
        <span className="flaticon-flutter" />
        <span className="flaticon-laravel" />
        <span className="flaticon-dart" />
        <span className="flaticon-firebase" />
      </div>
      {/*?php include("./components/contact.php") ?*/}
      <Contact/>
    </section>
    {/*?php
    include("./components/footer.php");
  ?*/}
  </>
  
}


export default MySchoolApp;