// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from "./Componets/Header";
import Footer from "./Componets/Footer";
import Contact from "./Componets/Contact";
import Faq from "./Componets/Faq";
import About from "./Componets/About";
import Service from "./Componets/Service";
import ServiceNav from "./Componets/ServiceNav";
import Testimonial from "./Componets/Testimonial";
import Blogs from "./Componets/Blogs";
import BrightWorldMobileApp from "./Componets/BrightWorldMobileApp";
import Home from "./Componets/Home";
import Services from "./Componets/Services";
import Contact2 from "./Componets/Contact2";
import Portfolio from "./Componets/Portfolio";
import MySchoolApp from "./Componets/MySchoolApp";
import MySchoolStaff from "./Componets/MySchoolStaff";
import MySchoolErp from "./Componets/MySchoolErp";
import MySchoolAdminApp from "./Componets/MySchoolAdminApp";
import LeadManagementSystem from "./Componets/LeadManagementSystem";
import YccWeb from "./Componets/YccWeb";
import DrAshokEcommerce from "./Componets/DrAshokEcommerce";
import YccMobileApp from "./Componets/YccMobileApp";
import ProjectTracker from "./Componets/ProjectTracker";
import MyCartProEcom from "./Componets/MyCartProEcom";
import PropointWebsite from "./Componets/PropointWebsite";
import SocialMediaComponent from "./Componets/SocialMediaComponent";
import WebDevelopment from "./Componets/WebDevelopment";
import WebSiteDevelopment from "./Componets/WebsiteDevelopment";
import UiUxDesigningDev from "./Componets/UiUxDesigningDev";
import UiUxDesigning from "./Componets/UiUxDesigning";
import VLA from "./Componets/VLA";
import MobileAppDevelopment from "./Componets/MobileAppDevelopment";
import CustomSoftwareDevelopment from "./Componets/CustomSoftwareDevelopment";
import CustomSoftwareDevelopmentService from "./Componets/CustomSoftwareDevelopmentService";
import MobileApplicationDevelopment from "./Componets/MobileApplicationDevelopment";
import ProPointWebApp from "./Componets/ProPointWebApp";
import Codoc from "./Componets/Codoc";
import Loader from "./Componets/Loader";
import CustomSoftwareDevelopmentPortfolio from "./Componets/CustomSoftwareDevelopmentPortfolio";
import UnderDev from './Componets/UnderDev';

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    window.scrollTo(0, 0);
    setLoading(true);
    const timer = setTimeout(handleLoad, 2000); // Show loader for 2 seconds

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      <Header />
      {!loading && (
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service/mobile-app-development" element={<MobileAppDevelopment />} />
          <Route path="/contact-us" element={<Contact2 />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/myschool" element={<MySchoolApp />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/portfolio/myschool-mobile-app" element={<MySchoolApp />} />
          <Route path="/portfolio/myschool-erp" element={<MySchoolErp />} />
          <Route path="/portfolio/myschool-staff-app" element={<MySchoolStaff />} />
          <Route path="/portfolio/myschool-admin-app" element={<MySchoolAdminApp />} />
          <Route path="/portfolio/leads-management-system" element={<LeadManagementSystem />} />
          <Route path="/portfolio/ycc-web-app" element={<YccWeb />} />
          <Route path="/portfolio/dr-ashok-ecommerce" element={<DrAshokEcommerce />} />
          <Route path="/portfolio/ycc-mobile-app" element={<YccMobileApp />} />
          <Route path="/portfolio/project-tracker" element={<ProjectTracker />} />
          <Route path="/portfolio/mycart-pro-ecom" element={<MyCartProEcom />} />
          <Route path="/portfolio/bright-world-mobile-app" element={<BrightWorldMobileApp />} />
          <Route path="/portfolio/propoint-website" element={<PropointWebsite />} />
          <Route path="/services/social-media-management" element={<SocialMediaComponent />} />
          <Route path="/portfolio/web-development" element={<WebDevelopment />} />
          <Route path="/services/web-development" element={<WebSiteDevelopment />} />
          <Route path="/services/uiux-design-dev" element={<UiUxDesigningDev />} />
          <Route path="/portfolio/ui-ux-designing" element={<UiUxDesigning />} />
          <Route path="/portfolio/vla" element={<VLA />} />
          <Route path="/portfolio/mobile-application-development" element={<MobileApplicationDevelopment />} />
          <Route path="/services/custom-software-development" element={<CustomSoftwareDevelopmentService />} />
          <Route path="/portfolio/custom-software-development" element={<CustomSoftwareDevelopmentPortfolio />} />
          <Route path="/mobile-application-development" element={<MobileApplicationDevelopment />} />
          <Route path="/portfolio/propoint-web-app" element={<ProPointWebApp />} />
          <Route path="/portfolio/codoc-mobile-app" element={<Codoc />} />
          <Route path="*" element={<UnderDev />} />
        </Routes>
      )}
      <Footer />
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
