import custom_software from "../images/custom-software-1.webp";
import images_web2 from "../images/web2.webp";
import YCC from "../projects/YCC-SD.webp";
import Propoint from "../projects/Propoint-SD.webp";
import LegalStix from "../projects/Legalstix-CRM-SD.webp";
import { Link } from "react-router-dom";

const CustomSoftwareDevelopmentService = () => {
    return (
        <>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url('${images_web2}')` }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-end">
                        <div className="col-md-9 pb-5">
                            <p className="breadcrumbs">
                                <span className="mr-2">
                                    <a href="../">Home <i className="fa fa-chevron-right"></i></a>&nbsp;
                                    <a href="../services">Services <i className="fa fa-chevron-right"></i></a>
                                </span>
                                <span>Custom Software Development</span>
                            </p>
                            <h1 className="mb-0 bread">Custom Software Development</h1>
                            {/* <p className="breadcrumbs">
                                <span className="mr-2">
                                    Our mobile app development services cover a wide spectrum, including Android app development, iOS app development, and cross-platform app development. We leverage the latest technologies and industry best practices to create intuitive, engaging, and high-performing mobile applications that resonate with your target audience.
                                </span>
                            </p> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section ftco-no-pb">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 heading-section">
                            <span className="subheading">Custom Software Development</span>
                            <h2>EMPOWERING INNOVATION</h2>
                            <p>
                                In today's digital age, having robust and scalable software solutions is essential for businesses to streamline processes, enhance productivity, and stay competitive. Our software development services encompass a wide range of solutions, including CRM systems, Ecommerce Software, ERP solutions, enterprise applications, cloud-based solutions, and more. We collaborate closely with our clients to understand their unique requirements, workflows, and pain points. Our team of experienced developers then employs agile methodologies and best-in-class technologies to build customized software solutions that address your business challenges and drive growth. We focus on delivering high-quality software products that are scalable, secure, and optimized for performance, ensuring long-term success and value for your organization.
                            </p>
                        </div>
                        <div className="col-md-5">
                            <img src={custom_software} width="100%" height="auto" alt="Custom Software Development" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section ftco-no-pb">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 heading-section">
                            <span className="subheading">Custom Software Development</span>
                            <h2>Our Custom Software Development Process</h2>
                            <ul className="pricing-text mb-2 list-unstyled" style={{ color: 'black' }}>
                                <li>
                                    <span className="fa fa-check-circle mr-2"></span>
                                    <b>Comprehensive Software Solutions: </b>
                                    Our software development services encompass a wide range of solutions, including CRM systems, eCommerce software, ERP solutions, enterprise applications, cloud-based solutions, and more. We cater to diverse business needs and ensure that each solution is tailored to meet specific requirements. This broad expertise allows us to provide comprehensive solutions that drive business efficiency and growth.
                                </li>
                                <li>
                                    <span className="fa fa-check-circle mr-2"></span>
                                    <b>Client Collaboration: </b>
                                    We collaborate closely with our clients to understand their unique requirements, workflows, and pain points. This collaborative approach ensures that we develop software solutions that are perfectly aligned with your business processes. By involving clients in every step of the development process, we create solutions that truly address their challenges.
                                </li>
                                <li>
                                    <span className="fa fa-check-circle mr-2"></span>
                                    <b>Agile Development Methodologies: </b>
                                    Our team of experienced developers employs agile methodologies and best-in-class technologies to build customized software solutions. This approach allows for flexibility, iterative development, and continuous improvement. By using agile practices, we can quickly adapt to changes and deliver high-quality software products that meet your evolving needs.
                                </li>
                                <li>
                                    <span className="fa fa-check-circle mr-2"></span>
                                    <b>High-Quality, Scalable, and Secure Solutions: </b>
                                    We focus on delivering high-quality software products that are scalable, secure, and optimized for performance. Our solutions are designed to support long-term growth and provide significant value to your organization. Ensuring the security and scalability of our software products guarantees their effectiveness and reliability over time.
                                </li>
                                <li>
                                    <span className="fa fa-check-circle mr-2"></span>
                                    <b>Delivering Measurable Results: </b>
                                    Our software solutions are not just about looks; they are designed to deliver measurable results. We implement analytics and tracking tools to monitor site performance and user behavior. This data-driven approach allows us to continually refine and improve the software to meet your business goals.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* PORTFOLIO START HERE */}
            <section className="ftco-section ftco-no-pb">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row justify-content-between pb-5">
                        <div className="col-md-6 col-lg-6 heading-section heading-section-white">
                            <span className="subheading">Recent Portfolio</span>
                            <h2 style={{ color: 'black' }}>Our Recent Projects</h2>
                        </div>
                        <div className="col-md-4 col-lg-3 d-flex align-items-center justify-content-end">
                            <Link to="../portfolio/custom-software-development" className="btn-custom">
                                View All Projects <span className="fa fa-chevron-right"></span>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="project-wrap img d-flex align-items-end" style={{ backgroundImage: `url("${YCC}")` }}>
                                <div className="text">
                                    <span>Yogyata Coaching Centre</span>
                                    <h3><Link href="./ycc-app-app.php">Interior Design</Link></h3>
                                    <Link to="../portfolio/ycc-web-app" className="icon d-flex align-items-center justify-content-center">
                                        <span className="fa fa-chevron-right"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="project-wrap img d-flex align-items-end" style={{ backgroundImage: `url("${Propoint}")` }}>
                                <div className="text">
                                    <span>ProPoint Connect</span>
                                    <h3><Link to="../portfolio/propoint-web-app">Interior Design</Link></h3>
                                    <Link to="../portfolio/propoint-web-app" className="icon d-flex align-items-center justify-content-center">
                                        <span className="fa fa-chevron-right"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="project-wrap img d-flex align-items-end" style={{ backgroundImage: `url('${LegalStix}')` }}>
                                <div className="text">
                                    <span>Leads CRM</span>
                                    <h3><a href="./leads-management-system.php">Interior Design</a></h3>
                                    <a href="./leads-management-system.php" className="icon d-flex align-items-center justify-content-center">
                                        <span className="fa fa-chevron-right"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* PORTFOLIO SECTION END HERE */}

            <section className="ftco-section ftco-no-pb">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 heading-section">
                            <span className="subheading">Tech Stack</span>
                            <h2>Technologies Used Are</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-2 py-4" style={{ textAlign: 'center' }}>
                            <span className="flaticon-node"></span>
                            <span className="flaticon-mysql"></span>
                            <span className="flaticon-mongodb"></span>
                            <span className="flaticon-react"></span>
                            <span className="flaticon-php"></span>
                            <span className="flaticon-laravel"></span>
                            <span className="flaticon-javascript"></span>
                            <span className="flaticon-html"></span>
                            <span className="flaticon-css"></span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CustomSoftwareDevelopmentService;
