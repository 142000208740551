import React from "react";
import customSoftwareDev from "../images/customSoftwareDev.webp";
import LegalStix from "../projects/Legalstix-CRM-SD.webp";
import YCC from "../projects/YCC-SD.webp";
import Propoint from "../projects/Propoint-SD.webp";
import VLA from "../projects/VLA-SD.webp";
import { Link } from "react-router-dom";

const CustomSoftwareDevelopmentPortfolio = () => {
  return (
    <>
      <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url('${customSoftwareDev}')` }}>
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end">
            <div className="col-md-9 pb-5">
              <p className="breadcrumbs">
                <span className="mr-2"><a href="portfolio.php">Portfolio <i className="fa fa-chevron-right"></i></a></span> 
                <span>Custom Software Development <i className="fa fa-chevron-right"></i></span>
              </p>
              <h1 className="mb-0 bread">Custom Software Development</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-12 about-intro">
              <div className="row d-flex">
                <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
                  <div className="project-wrap img d-flex align-items-end" style={{ backgroundImage: `url('${LegalStix}')`, borderRadius: "30px" }}>
                    <div className="text">
                      <span>Leads Management System</span>
                      <h3><a href="./leads-management-system.php">Interior Design</a></h3>
                      <a href="./leads-management-system.php" className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-chevron-right"></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-2 pl-md-5 py-5">
                  <div className="row justify-content-start pb-3 pt-md-5">
                    <div className="col-md-12 heading-section">
                      <h2 className="mb-4 heading">Leads Management System</h2>
                      <p>MCodez IT Solutions proudly presents the Levnext Projects CRM, a comprehensive customer relationship management platform designed to enhance business operations and client interactions. This platform offers robust tools for managing client information, tracking sales, and optimizing customer engagement strategies.</p>
                    </div>
                  </div>
                  <div style={{ border: "2px solid black" }}></div>
                </div>

                <div className="col-md-6 order-2 order-md-1 pl-md-5 py-5">
                  <div className="row justify-content-start pb-3 pt-md-5">
                    <div className="col-md-12 heading-section">
                      <h2 className="mb-4">YOGYATA WEB APPLICATION</h2>
                      <p>At MCodez IT Solutions, we specialize in creating secure and efficient web applications tailored to specific business needs. One of our standout projects is the YCC Management Portal, designed to streamline the operations and management of Youth Cultural Center activities.</p>
                    </div>
                  </div>
                  <div style={{ border: "2px solid black" }}></div>
                </div>
                <div className="col-md-6 order-1 order-md-2 d-flex align-items-stretch mt-2 py-2">
                  <div className="project-wrap img d-flex align-items-end" style={{ backgroundImage: `url('${YCC}')`, borderRadius: "30px" }}>
                    <div className="text">
                      <span>YOGYATA WEB APPLICATION</span>
                      <h3><Link href="../portfolio/ycc-web-app">Interior Design</Link></h3>
                      <Link href="../portfolio/ycc-web-app" className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-chevron-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
                  <div className="project-wrap img d-flex align-items-end" style={{ backgroundImage: `url('${Propoint}')`, borderRadius: "30px" }}>
                    <div className="text">
                      <span>ProPoint Web Application</span>
                      <h3><Link to="../portfolio/propoint-web-app">Interior Design</Link></h3>
                      <Link to="../portfolio/propoint-web-app" className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-chevron-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-2 pl-md-5 py-5">
                  <div className="row justify-content-start pb-3 pt-md-5">
                    <div className="col-md-12 heading-section">
                      <h2 className="mb-4">ProPoint Web Application</h2>
                      <p>MCodez IT Solutions proudly presents ProPoint Connect, an advanced job portal designed to streamline the hiring process for administrators, clients, and vendors. This platform provides a centralized solution for job postings, candidate management, and vendor coordination, ensuring an efficient and effective recruitment process.</p>
                    </div>
                  </div>
                  <div style={{ border: "2px solid black" }}></div>
                </div>

                <div className="col-md-6 order-2 pl-md-5 py-5">
                  <div className="row justify-content-start pb-3 pt-md-5">
                    <div className="col-md-12 heading-section">
                      <h2 className="mb-4">VLA Web Application</h2>
                      <p>MCodez IT Solutions is pleased to introduce the VLA, a specialized e-commerce platform dedicated to the sale of steel products. This platform is designed to streamline the buying process for industrial clients, providing a seamless and efficient online shopping experience for a wide range of steel products.</p>
                    </div>
                  </div>
                  <div style={{ border: "2px solid black" }}></div>
                </div>
                <div className="col-md-6 order-1 d-flex align-items-stretch mt-2 py-2">
                  <div className="project-wrap img d-flex align-items-end" style={{ backgroundImage: `url('${VLA}')`, borderRadius: "30px" }}>
                    <div className="text">
                      <span>VLA Web Application</span>
                      <h3><Link to="../portfolio/vla">Interior Design</Link></h3>
                      <Link to="../portfolio/vla" className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-chevron-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomSoftwareDevelopmentPortfolio;
