import faq from "../images/faq_bg.webp";

const Faq=()=>{
    return <>
    {/* FAQ SECTION START HERE  */}
    <section className="ftco-section ftco-faqs services-section bg-light">
      <div className="overlay" />
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 mb-5 md-md-0 order-md-last">
            <div
              className="img w-100 mb-4"
              style={{ backgroundImage: `url(${faq})` }}
            />
            <h2 className="heading-section2 mb-5">
              We Are Very <br />
              Experienced &amp; Professionals
            </h2>
            <div className="row">
              <div className="col-md-12 animate-box">
                <div className="progress-wrap ">
                  <h3>Web Development</h3>
                  <div className="progress">
                    <div
                      className="progress-bar color-1"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "90%" }}
                    >
                      <span>95%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 animate-box">
                <div className="progress-wrap ">
                  <h3>Mobile App Development</h3>
                  <div className="progress">
                    <div
                      className="progress-bar color-2"
                      role="progressbar"
                      aria-valuenow={90}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "85%" }}
                    >
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 animate-box">
                <div className="progress-wrap ">
                  <h3>Software Development</h3>
                  <div className="progress">
                    <div
                      className="progress-bar color-1"
                      role="progressbar"
                      aria-valuenow={50}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "90%" }}
                    >
                      <span>95%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 animate-box">
                <div className="progress-wrap ">
                  <h3>Social Media Mangement</h3>
                  <div className="progress">
                    <div
                      className="progress-bar color-2"
                      role="progressbar"
                      aria-valuenow={90}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "85%" }}
                    >
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 heading-section pr-md-5  d-flex align-items-center">
            <div className="w-100 mb-4 mb-md-0">
              <span className="subheading">Freequesntly Ask Question</span>
              <h2 className="mb-5">
                Frequently <br />
                Ask Question
              </h2>
              <div
                id="accordion"
                className="myaccordion w-100"
                aria-multiselectable="true"
              >
                <div className="card">
                  <div className="card-header p-0" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        href="#collapseOne"
                        className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <p className="mb-0">How to fixed a problem?</p>
                        <i className="fa" aria-hidden="true" />
                      </button>
                    </h2>
                  </div>
                  <div
                    className="collapse show"
                    id="collapseOne"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                  >
                    <div className="card-body py-3 px-0">
                      <ol>
                        <li>
                          Identify the broken link using a link checker tool.
                        </li>
                        <li>
                          Locate the page or post containing the broken link.
                        </li>
                        <li>
                          Edit the content to update or remove the broken link.
                        </li>
                        <li>Save the changes and recheck the link.</li>
                        <li>
                          Monitor the site regularly for any future broken links.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header p-0" id="headingTwo" role="tab">
                    <h2 className="mb-0">
                      <button
                        href="#collapseTwo"
                        className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <p className="mb-0">How to manage your Website?</p>
                        <i className="fa" aria-hidden="true" />
                      </button>
                    </h2>
                  </div>
                  <div
                    className="collapse"
                    id="collapseTwo"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                  >
                    <div className="card-body py-3 px-0">
                      <ol>
                        <li>
                          Regularly update your content to keep it relevant.
                        </li>
                        <li>Perform routine backups to prevent data loss.</li>
                        <li>Optimize your website for speed and performance.</li>
                        <li>
                          Ensure your website is mobile-friendly and responsive.
                        </li>
                        <li>
                          Use analytics tools to track and improve your website's
                          performance.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header p-0" id="headingThree" role="tab">
                    <h2 className="mb-0">
                      <button
                        href="#collapseThree"
                        className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <p className="mb-0">
                          How to grow your investments funds?
                        </p>
                        <i className="fa" aria-hidden="true" />
                      </button>
                    </h2>
                  </div>
                  <div
                    className="collapse"
                    id="collapseThree"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                  >
                    <div className="card-body py-3 px-0">
                      <ol>
                        <li>Diversify your investment portfolio.</li>
                        <li>Invest regularly and consistently.</li>
                        <li>Stay informed about market trends and conditions.</li>
                        <li>
                          Consult with a financial advisor for expert guidance.
                        </li>
                        <li>
                          Review and adjust your investment strategy periodically.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header p-0" id="headingFour" role="tab">
                    <h2 className="mb-0">
                      <button
                        href="#collapseFour"
                        className="d-flex py-3 px-4 align-items-center justify-content-between btn btn-link"
                        data-parent="#accordion"
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <p className="mb-0">
                          What are those requirements for businesses?
                        </p>
                        <i className="fa" aria-hidden="true" />
                      </button>
                    </h2>
                  </div>
                  <div
                    className="collapse"
                    id="collapseFour"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                  >
                    <div className="card-body py-3 px-0">
                      <ol>
                        <li>Creating a comprehensive business plan.</li>
                        <li>Securing necessary financing or investment.</li>
                        <li>
                          Registering your business and obtaining required
                          licenses.
                        </li>
                        <li>
                          Choosing a suitable business structure (e.g., sole
                          proprietorship, LLC, corporation).
                        </li>
                        <li>Setting up accounting and bookkeeping systems.</li>
                        <li>
                          Understanding and complying with legal and tax
                          obligations.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* FAQ SECTION END HERE  */}
  </>
  
}

export default Faq;