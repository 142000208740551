import about from "../images/about.webp";
import backg from "../images/mcodez_bg.webp";
import vision from "../images/about_vision.webp"
import about_apporach from "../images/about_approach.webp"
import about_values from "../images/about_values.webp";
import Faq from "./Faq";
import Testimonial from "./Testimonial";
import { Link } from "react-router-dom";


const About=()=>{
    
   return <><section
  className="hero-wrap hero-wrap-2"
  style={{ backgroundImage: `url(${backg})` }}
>
  <div className="overlay" />
  <div className="container">
    <div className="row no-gutters slider-text align-items-end">
      <div className="col-md-9  pb-5">
        <p className="breadcrumbs">
          <span className="mr-2">
            <Link to="/">
              Home <i className="fa fa-chevron-right" />
            </Link>
          </span>{" "}
          <span>
            About us <i className="fa fa-chevron-right" />
          </span>
        </p>
        <h1 className="mb-0 bread">About Us</h1>
      </div>
    </div>
  </div>
</section>
   <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
  <div className="container">
    <div className="row d-flex">
      <div className="col-md-12 about-intro">
        <div className="row d-flex">
          <div className="col-md-6 d-flex align-items-stretch">
            <div
              className="img d-flex align-items-center align-self-stretch justify-content-center"
              style={{ backgroundImage: `url(${about})` }}
            ></div>
          </div>
          <div className="col-md-6 pl-md-5 py-5">
            <div className="row justify-content-start pb-3 pt-md-5">
              <div className="col-md-12 heading-section ftco-animate">
                <span className="subheading">
                  Welcome to MCodez IT Solutions
                </span>
                <h2 className="mb-4">
                  Highest <br />
                  Creative Standards
                </h2>
                <p>
                  At MCodez IT Solutions, we are dedicated to empowering
                  businesses with cutting-edge digital solutions. As one of the
                  top IT companies in India, we specialize in providing a wide
                  range of services, including UI/ UX design, mobile app
                  development, website development, and software development,
                  and digital marketing.
                </p>
                <div className="year-stablish d-flex">
                  <div className="icon2 d-flex align-items-center justify-content-center">
                    <span className="flaticon-light-bulb" />
                  </div>
                  <div className="text pl-4">
                    <strong className="number" data-number={42}>
                      21
                    </strong>
                    <span>
                      Year Of
                      <br /> Experienced
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
    <div className="container">
      <div className="row d-flex">
        <div className="col-md-12 about-intro">
          <div className="row d-flex">
            <div className="col-md-6 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">
                    <i className="fa-regular fa-flag" /> Our Vision
                  </h2>
                  <p>
                    Our journey began with a vision to redefine the standards of
                    excellence in the IT industry. Since our inception, we have
                    worked tirelessly to build a team of skilled professionals
                    who are passionate about innovation and committed to
                    delivering exceptional results.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-stretch">
              <div
                className="img d-flex align-items-center align-self-stretch justify-content-center"
                style={{ backgroundImage: `url(${vision})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
    <div className="container">
      <div className="row d-flex">
        <div className="col-md-12 about-intro">
          <div className="row d-flex">
            <div className="col-md-6 d-flex align-items-stretch">
              <div
                className="img d-flex align-items-center align-self-stretch justify-content-center"
                style={{ backgroundImage: `url(${about_apporach})` }}
              ></div>
            </div>
            <div className="col-md-6 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">
                    <span>
                      <i className="fa-regular fa-chess-rook" />
                    </span>{" "}
                    Our Approach
                  </h2>
                  <p>
                    At MCodez IT Solutions we believe in understanding the
                    unique needs and goals of each client and tailoring our
                    solutions to ensure their success. Whether you are a startup
                    looking to establish a strong online presence or an
                    established enterprise seeking to streamline your
                    operations, we have the expertise and resources to meet your
                    requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb img">
    <div className="container">
      <div className="row d-flex">
        <div className="col-md-12 about-intro">
          <div className="row d-flex">
            <div className="col-md-6 pl-md-5 py-5">
              <div className="row justify-content-start pb-3 pt-md-5">
                <div className="col-md-12 heading-section ">
                  <h2 className="mb-4">
                    <span>
                      <i className="fa-regular fa-face-grin" />
                    </span>{" "}
                    Our Values
                  </h2>
                  <p>
                    At MCodez IT Solutions, we are more than just a service
                    provider – we are your strategic partner in digital
                    transformation. Our values of integrity, innovation, and
                    excellence guide everything we do, ensuring that we deliver
                    value-driven solutions that exceed expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-stretch">
              <div
                className="img d-flex align-items-center align-self-stretch justify-content-center"
                style={{ backgroundImage: `url(${about_values})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="ftco-section ftco-counter img"
    id="section-counter"
    style={{ backgroundImage: `url(${about})`}}
  >
    <div className="overlay" />
    <div className="container">
      <div className="row">
        <div className="col-md-3 d-flex counter-wrap ">
          <div className="block-18 d-flex align-items-center">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="flaticon-file" />
            </div>
            <div className="text pl-3">
              <strong className="number" data-number={4800}>
                100
              </strong>
              <span>Project Completed</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex counter-wrap ">
          <div className="block-18 d-flex align-items-center">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="flaticon-waiter" />
            </div>
            <div className="text pl-3">
              <strong className="number" data-number={1000}>
                30
              </strong>
              <span>Our Staff</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex counter-wrap ">
          <div className="block-18 d-flex align-items-center">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="flaticon-customer-service" />
            </div>
            <div className="text pl-3">
              <strong className="number" data-number={350}>
                50
              </strong>
              <span>Services Provide</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 d-flex counter-wrap ">
          <div className="block-18 d-flex align-items-center">
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="flaticon-good-review" />
            </div>
            <div className="text pl-3">
              <strong className="number" data-number={7650}>
                1000
              </strong>
              <span>Happy Customers</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*?php
  include("./components/faq.php");
?*/}
 
<Faq/>
<Testimonial/>

</>

}
export default About;