import { Link } from "react-router-dom";



const Service=()=>{
        return <>
        {/* SERVICE SECTION START */}
        <section className="ftco-section ftco-services">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex align-self-stretch ">
                <div className="pb-4 heading-section heading-section-white">
                  <h2 className="mb-3">
                    We Shape The <br /> Perfect Solutions
                  </h2>
                  <p className="mb-4">
                    At MCodez IT Solutions, we understand the importance of staying
                    ahead in today's digital landscape. That's why we offer a
                    comprehensive suite of services designed to empower your business
                    for success. Whether you're looking to launch a user-friendly{" "}
                    <a href="">mobile app</a>, create a captivating{" "}
                    <a href="">website</a>, or develop{" "}
                    <a href="">custom software solutions</a>, we have the expertise
                    and experience to bring your ideas to life.
                  </p>
                </div>
              </div>
              <div className="col-md-3 d-flex align-self-stretch ">
                <div className="services">
                  <div className="d-flex justify-content-end">
                    <div className="icon d-flex">
                      <span className="flaticon-bullhorn" />
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="heading mb-3">
                      Social Media <br />
                      Management
                    </h3>
                  </div>
                  <Link
                    to="/services/social-media-management"
                    className="btn-custom d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
              <div className="col-md-3 d-flex align-self-stretch ">
                <div className="services">
                  <div className="d-flex justify-content-end">
                    <div className="icon d-flex">
                      <span className="flaticon-stats" />
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="heading mb-3">
                      Digital <br />
                      Marketing
                    </h3>
                  </div>
                  <Link
                    to="#"
                    className="btn-custom d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
              <div className="col-md-3 d-flex align-self-stretch ">
                <div className="services active">
                  <div className="d-flex justify-content-end">
                    <div className="icon d-flex">
                      <span className="flaticon-vector" />
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="heading mb-3">
                      Custom Software <br />
                      Development
                    </h3>
                  </div>
                  <Link
                    to="/services/custom-software-development"
                    className="btn-custom d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
              <div className="col-md-3 d-flex align-self-stretch ">
                <div className="services">
                  <div className="d-flex justify-content-end">
                    <div className="icon d-flex">
                      <span className="flaticon-branding" />
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="heading mb-3">
                      Application <br />
                      Development
                    </h3>
                  </div>
                  <Link
                    to="service/mobile-app-development"
                    className="btn-custom d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
              <div className="col-md-3 d-flex align-self-stretch ">
                <div className="services">
                  <div className="d-flex justify-content-end">
                    <div className="icon d-flex">
                      <span className="flaticon-web-programming" />
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="heading mb-3">
                      Web <br />
                      Development
                    </h3>
                  </div>
                  <Link
                    to="/services/web-development"
                    className="btn-custom d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
              <div className="col-md-3 d-flex align-self-stretch ">
                <div className="services">
                  <div className="d-flex justify-content-end">
                    <div className="icon d-flex">
                      <span className="flaticon-ux" />
                    </div>
                  </div>
                  <div className="media-body">
                    <h3 className="heading mb-3">
                      UX/UI <br />
                      Design
                    </h3>
                  </div>
                  <Link
                    to="/portfolio/ui-ux-designing"
                    className="btn-custom d-flex align-items-center justify-content-center"
                  >
                    <span className="fa fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* SERVICE SECTION END  */}
      </>
      
}

export default Service;